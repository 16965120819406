import { ReactNode } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/page-header.scss";

export type PageHeaderProps = {
  title: string;
  children?: ReactNode;
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <div className="title-header">
      <Container className="title-container">
        <Row className="title-row">
          <Col>
            <h1>{props.title}</h1>
          </Col>
          {props.children}
        </Row>
      </Container>
    </div>
  );
}

export default PageHeader;