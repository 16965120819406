import { UserData } from "./types/user-data";

const Constants = {
    Roles: {
        admin: 'admin',
        adminuser: 'adminuser',
        anonymous: 'anonymous',
    },
    Service: {
        Status: {
            active: "active",
            inactive: "inactive"
        },
        Types: {
            national: "national",
            nationalLibrary: "rara"
        },
        Designs: {
            national: "national",
            nationalLibrary: "rara"
        },
        RegistrationTemplates: {
            national: "national",
            nationalLibrary: "rara"
        },
        MailTemplates: {
            national: "national",
            nationalLibrary: "rara"
        }
    },
    ClassifierTypes: {
      Language: "Language",
      SamlNameIdFormat: "SamlNameIdFormat",
      SamlBinding: "SamlBinding",
    }
};
export default Constants;



export const AuthTypes = [
	{ code: "HarID", name: "HarID" },	// $t("HarID")
	{ code: "TAAT", name: "TAAT" },	// $t("TAAT")
	{ code: "IDCard", name: "IDCard" },	// $t("IDCard")
	{ code: "MobileID", name: "MobileID" },	// $t("MobileID")
  { code: "SmartID", name: "SmartID" },	// $t("SmartID")
  { code: "eIDAS", name: "eIDAS" },
	{ code: "Password", name: "Password" }	// $t("Password")
];

export const FieldTypes = [
    { name: "string", title: "Tekst" },
    { name: "date", title: "Kuupäev" },
    { name: "person_code", title: "Isikukood" },
    { name: "email", title: "E-mail" },
    { name: "address", title: "Aadress" },
    { name: "multi_choice", title: "Valik" },
];

export const ServiceTypes = [
	{ name: "rara", title: "Rahvusraamatukogu" },
	{ name: "national", title: "Üleriigiline" }
];

export const YesNoChoice = [
	{ value: true, text: "Jah" },
	{ value: false, text: "Ei" }
];

export const ServiceAuthTypes = [
	{ code: "OIDC", text: "OIDC" },
	{ code: "SAML", text: "SAML" },
	{ code: "CASv1", text: "CAS v1.0" },
];

export const LoggingOutFrom = [
	{ code: "this_app", text: "Ainult sellest rakendusest" },
	{ code: "all_apps", text: "Kõikidest selle teenuse rakendustest" },
];

export const UserRights = {
    MENETLEJA: 16,
    ADMIN: 3,
    MAIN_USER: 2,

    format(rights: number) {
        if (rights == this.ADMIN)
            return "Administrator";
        if (rights == this.MAIN_USER)
            return "Peakasutaja";
        if (rights == this.MENETLEJA)
            return "Menetleja";
        return "";
    },
    isAdmin(rights: number) {
        return (rights & this.ADMIN) == this.ADMIN;
	}
}

export const DefaultLanguage = "et";
	
