import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";

export const StatisticsService = { 
  fetchAuthenticationStatisticLogs(validFrom: string, validTo: string, granuality: number, serviceIds : Number[] | undefined) {
    return new Promise((resolve, reject) => {
        const url = BackendConfig.Url() + `/Report/GetAuthenticationReport`;
        BackendApi.post(url,{fromDate: validFrom, toDate: validTo, serviceIds: serviceIds, granuality: granuality})
            .then(resp => resolve(resp))
            .catch(ex => reject(ex));
    });
  },
  fetchRegistrationStatisticLogs(validFrom: string, validTo: string, granuality: number, serviceIds : number[]| undefined) {
    return new Promise((resolve, reject) => {
        const url = BackendConfig.Url() + `/Report/GetRegistrationReport`;
        BackendApi.post(url, {fromDate: validFrom, toDate: validTo, serviceIds: serviceIds, granuality: granuality})
            .then(resp => resolve(resp))
            .catch(ex => reject(ex));
    });
  },
  sendReportEmail(validFrom: string, validTo: string, granuality: number, serviceIds : number[]| undefined, type:number, email: string) {
    return new Promise((resolve, reject) => {
        const url = BackendConfig.Url() + `/Report/SendEmail`;
        BackendApi.post(url, {fromDate: validFrom, toDate: validTo, granuality: granuality, serviceIds: serviceIds, reportType: type, email: email})
            .then(resp => resolve(resp))
            .catch(ex => reject(ex));
    });
  },
  
  
  downloadReportCsv: async function (validFrom: string, validTo: string, granuality: number, serviceIds : Number[] | undefined, type: number): Promise<any> {
    let url = '';
    if(type === 1){
      url = BackendConfig.Url() + `/Report/AuthenticationCsv`;
    }else{
      url = BackendConfig.Url() + `/Report/RegistrationCsv`;
    }
    BackendApi.postAndDownloadWithCred(url, 'report.csv', {fromDate: validFrom, toDate: validTo, serviceIds: serviceIds, granuality: granuality});
  },
};
