import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import httpBackend from "i18next-http-backend";
import BackendConfig from "../backend-config";

i18n
	.use(httpBackend)
	.use(initReactI18next)
	.init({
		debug: false,
		lng: "et",
		fallbackLng: false,
		interpolation: {
			escapeValue: false
		},
		backend: { loadPath: "/static/locales/{{lng}}.json" },
		react: {
			useSuspense: false
		}
	});

export const $t = (key: string) => {
	return i18n.t(key);
}

export default i18n;
