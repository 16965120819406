import { useState } from "react";
import { Container, Button } from "react-bootstrap";
import AlertClose from "../../components/icons/alert-close";
import AlertInfo from "../../components/icons/alert-info"

type PageAlertProps = {
    message: string
}

const PageAlert = (props: PageAlertProps) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <>
          {isVisible && (
            <div className="page-alert">
              <Container>
                <div className="cont">
                  <div className="alert-icon">
                    <AlertInfo />
                  </div>
                  <div className="message">{props.message}</div>
                  <div className="alert-close">
                    <Button variant="icon" onClick={() => setIsVisible(false)}>
                      <AlertClose />
                    </Button>
                  </div>
                </div>
              </Container>
            </div>
          )}
        </>
      );
}

export default PageAlert;
