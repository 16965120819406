export const showError = async (data: any) => {
  let resp: any = null;

  try {
    resp = await data.clone().json();
  } catch (err) {
    resp = await data.clone().text();
  }

  alert(resp);
};

export const showValidationErrors = (resp: any, setLocalErrors: Function) => {
  if (resp.errors === undefined) return false;

  let tmp = {} as { [key: string]: any };
  for (let prop in resp.errors) {
    if (!Object.prototype.hasOwnProperty.call(resp.errors, prop)) continue;
    if (resp.errors[prop].length == 1) tmp[prop] = resp.errors[prop][0];
    else if (resp.errors[prop].length > 1) tmp[prop] = resp.errors[prop];
  }

  setLocalErrors(tmp);

  return true;
};

export const hideValidationErrors = (
  localErrors: any,
  setLocalErrors: Function
) => {
  let tmp = { ...localErrors };
  for (let i in tmp) {
    if (tmp.hasOwnProperty(i)) {
      tmp[i] = null;
    }
  }
  setLocalErrors(tmp);
};

export const hasValidationErrors = (
  localErrors: any,
) : boolean => {
  let tmp = { ...localErrors };
  for (let i in tmp) {
    if (tmp.hasOwnProperty(i)) {
      return true
    }
  }
  return false
};

export const downloadFile = (blob: Blob, filename: string) => {
  const link = document.createElement("a");
  const data = window.URL.createObjectURL(blob);
  link.href = data;
  link.download = filename;
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const isEmpty = (value: any): boolean => {
  return (
    // null or undefined
    (value == null) ||

    // has length and it's zero
    (value.hasOwnProperty('length') && value.length === 0) ||

    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  )
}
