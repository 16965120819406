import { downloadFile } from "./hooks/helpers";

const BackendApi = {
  get: async function (url: string, noloader?: boolean): Promise<any> {
    if (noloader != true)
      this.showLoader();

    const data = await fetch(url, {
      method: "GET",
      redirect: "manual",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json"
      })
    });

    if (!data.ok) {
      this.handleError(data);
    } 
    let resp: any = null;

    try {
      resp = await data.clone().json();
    } catch (err) {
      resp = await data.clone().text();
    }

    this.hideLoader();
    return resp;
  },

  post: async function (url: string, requestbody?: any): Promise<any> {
    if (url.indexOf("/Logging/") == -1)//do not show loader for logging requests
      this.showLoader();

    const data = await fetch(url, {
      credentials: "include",
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json"
      }),
      body: JSON.stringify(requestbody)
    });

    if (!data.ok) {
      this.handleError(data);
    }

    let resp: any = null;

    try {
      resp = await data.clone().json();
    } catch (err) {
      resp = await data.clone().text();
    }

    this.hideLoader();
    return resp;
  },

  upload: async function (url: string, formData: FormData, showLoader?: boolean): Promise<any> {
    if (url.indexOf("/Logging/" ) == -1 || !!showLoader)//do not show loader for logging requests
      this.showLoader();

    const data = await fetch(url, {
      credentials: "include",
      method: "POST",
      body: formData
    });

    if (!data.ok) {
      this.handleError(data);
    }

    let resp: any = null;

    try {
      resp = await data.clone().json();
    } catch (err) {
      resp = await data.clone().text();
    }

    this.hideLoader();
    return resp;
  },

  put: async function (url: string, requestbody?: any): Promise<any> {
    if (url.indexOf("/Logging/") == -1)//do not show loader for logging requests
      this.showLoader();

    const data = await fetch(url, {
      credentials: "include",
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json"
      }),
      
      body: JSON.stringify(requestbody)
    });

    if (!data.ok) {
      this.handleError(data);
    }

    let resp: any = null;

    try {
      resp = await data.clone().json();
    } catch (err) {
      resp = await data.clone().text();
    }

    this.hideLoader();
    return resp;
  },

  delete: async function (url: string, requestbody?: any): Promise<any> {
    if (url.indexOf("/Logging/") == -1)//do not show loader for logging requests
      this.showLoader();

    const data = await fetch(url, {
      credentials: "include",
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json"
      }),
      body: JSON.stringify(requestbody)
    });

    if (!data.ok) {
      this.handleError(data);
    }

    let resp: any = null;

    try {
      resp = await data.clone().json();
    } catch (err) {
      resp = await data.clone().text();
    }

    this.hideLoader();
    return resp;
  },
  
  getAndDownload: async function (url: string, filename: string, noloader?: boolean): Promise<any> {
    if (!noloader)
      this.showLoader();

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        redirect: "manual",
        credentials: "same-origin",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      }).then(resp => {
        if (resp.status == 200) {
          resp.headers.forEach(console.log);
          resp.blob().then(b => downloadFile(b, filename));
          resolve({ success: true });
        }
        else {
          resp.clone().json().then(j => resolve(j));
        }
        this.hideLoader();
      })
        .catch(ex => {
          this.hideLoader();
          reject(ex);
        });
    });
  },

  getAndDownloadWithCred: async function (url: string, filename: string, noloader?: boolean): Promise<any> {
    if (!noloader)
      this.showLoader();

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        redirect: "manual",
        credentials: "include",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      }).then(resp => {
        if (resp.status == 200) {
          resp.headers.forEach(console.log);
          resp.blob().then(b => downloadFile(b, filename));
          resolve({ success: true });
        }
        else {
          resp.clone().json().then(j => resolve(j));
        }
        this.hideLoader();
      })
        .catch(ex => {
          this.hideLoader();
          reject(ex);
        });
    });
  },
  postAndDownloadWithCred: async function (url: string, filename: string, data:any, noloader?: boolean): Promise<any> {
    if (!noloader)
      this.showLoader();

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        redirect: "manual",
        credentials: "include",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify(data)
      }).then(resp => {
        if (resp.status == 200) {
          resp.headers.forEach(console.log);
          resp.blob().then(b => downloadFile(b, filename));
          resolve({ success: true });
        }
        else {
          resp.clone().json().then(j => resolve(j));
        }
        this.hideLoader();
      })
        .catch(ex => {
          this.hideLoader();
          reject(ex);
        });
    });
  },
 
  handleError(data: any) {
    this.hideLoader();
    console.log(data);

    if (data.type === "opaqueredirect") {
      //redirect
      if (data.url)
        location.href = data.url;
    }


    if (data.status === 400) {
      //Bad request
      throw data;
    }
    else if (data.status === 401) {
      //Unauthorized
      throw data;
    }
    else if (data.status === 403) {
      //Forbidden for user
      throw data;
    }
    else if (data.status === 500) {
      //Server error
      data.clone().text().then((data: any) => {
        try {
        } catch (e) { console.log(e); console.log(data); }
      });
    }
  },

  showLoader() {
    const loader = document.getElementById("loader");
    if (loader)
      loader.style.display = "grid";
  },
  hideLoader() {
    const loader = document.getElementById("loader");
    if (loader)
      loader.style.display = "none";
  },
 
};
export default BackendApi;

