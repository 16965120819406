import { Button, Modal } from "react-bootstrap";

export function DownloadModal(props: {
    isOpen: boolean,
    onClose: () => void
    downloadLink: string | null
}): JSX.Element {
    function downloadFile() {
        if (props.downloadLink) {
            window.open(props.downloadLink, "_blank");
            props.onClose();
            return;
        }
    }

    return (
        <Modal show={props.isOpen} onHide={props.onClose} size="sm" centered={true}>
            <Modal.Body>
                <div>Faili nägemiseks on vaja fail alla laadida</div>
                {!props.downloadLink && 
                    <p className="text-danger">Faili ei saa alla laadida. Võtke ühendust administraatoriga.</p>
                }

                <div style={{float:"right"}}>
                    {props.downloadLink &&
                        <Button variant="primary" onClick={() => downloadFile()}>Lae alla</Button>
                    }
                    <Button style={{marginLeft: "10px"}} variant="secondary" onClick={props.onClose}>
                        Sulge
                    </Button>
                </div>

            </Modal.Body>
        </Modal>
    );
}
