
const AlertInfo = () => {
	return (<svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M23.7153 1.20822C11.2703 1.20822 1.18066 9.63371 1.18066 20.0284C1.23293 22.6163 1.8586 25.1595 3.01161 27.4706C4.16463 29.7817 5.81563 31.802 7.84309 33.3828L3.1402 44.7918L15.6264 37.5787C18.242 38.4204 20.9704 38.8487 23.7153 38.8486C36.1623 38.8486 46.25 30.4231 46.25 20.0284C46.25 9.63371 36.1623 1.20822 23.7153 1.20822Z" stroke="black" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M24.695 28.9432V17.0568C24.695 16.5314 24.4885 16.0275 24.121 15.656C23.7535 15.2844 23.2551 15.0757 22.7354 15.0757H20.7759" stroke="black" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M20.7759 28.9432H28.614" stroke="black" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M24.2052 10.123C24.3021 10.123 24.3968 10.1521 24.4774 10.2065C24.5579 10.2609 24.6207 10.3383 24.6578 10.4288C24.6949 10.5193 24.7046 10.6189 24.6857 10.7149C24.6668 10.811 24.6201 10.8993 24.5516 10.9685C24.4831 11.0378 24.3958 11.085 24.3008 11.1041C24.2058 11.1232 24.1073 11.1134 24.0177 11.0759C23.9282 11.0384 23.8517 10.9749 23.7979 10.8935C23.7441 10.812 23.7153 10.7163 23.7153 10.6183C23.7153 10.487 23.7669 10.361 23.8588 10.2681C23.9507 10.1752 24.0753 10.123 24.2052 10.123Z" stroke="black" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
	</svg>);
};

export default AlertInfo;
