import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";

export const ActivityLogService = { 
  fetchActivityLogs(page: number, pageSize: number, validFrom: string, validTo: string) {
    return new Promise((resolve, reject) => {
        const url = BackendConfig.Url() + `/ActivityLog/Search?page=${page}&pageSize=${pageSize}&fromDate=${validFrom}&toDate=${validTo}`;
        BackendApi.get(url)
            .then(resp => resolve(resp))
            .catch(ex => reject(ex));
    });
  },
  
  downloadActivityLogCsv: async function (validFrom: string, validTo: string): Promise<any> {
    const url = BackendConfig.Url() + `/ActivityLog/csv?fromDate=${validFrom}&toDate=${validTo}`;
    BackendApi.getAndDownloadWithCred(url, 'logs.csv');
  },
};
