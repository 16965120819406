import React from "react";
import { Container } from "react-bootstrap";
import { Routes } from "react-router";
import { Route } from "react-router-dom";
import { showError } from "../hooks/helpers";
import { ServicesService } from "../services/services-service";
import { ServiceData } from "../types/service-data";
import EditUserPage from "./edit-user-page";
import UsersPage from "./users-page";

const UsersPageBase = () => {
    const [allServices, setAllServices] = React.useState<ServiceData[] | null>(null);

    React.useEffect(() => {
        const loadData = () => {
            ServicesService.getAllServices()
                .then((servResp: any) => {
                    setAllServices(servResp as ServiceData[]);
                })
                .catch(ex => showError(ex));
        }
        loadData();
    }, []);

    if (allServices == null)
        return null;

    return <Container fluid className="title-header">
        <Routes>
            <Route path="" element={<UsersPage allServices={allServices} />} />
            <Route path=":id" element={<EditUserPage allServices={allServices} />} />
        </Routes>
	</Container>
}

UsersPageBase.displayName = "UsersPageBase";

export default UsersPageBase;
