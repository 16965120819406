import BackendApi from "backend-api";
import BackendConfig from "backend-config";
import { IEmailTemplate } from "pages/email-templates/email-template";
import { IEmailTemplateType } from "pages/email-templates/email-template-type";

export const EmailTemplateService = {
  getAllEmailTemplates: async function (): Promise<IEmailTemplate[]> {
    let url = BackendConfig.Url() + "/EmailTemplate/All";
    const result = await BackendApi.get(url);

    return result;
  },
  getAllEmailTemplateTypes: async function (): Promise<IEmailTemplateType[]> {
    let url = BackendConfig.Url() + "/EmailTemplateType/All";
    const result = await BackendApi.get(url);

    return result;
  },
  getEmailTemplateTypeById: async function (id: string): Promise<IEmailTemplateType> {
    let url = BackendConfig.Url() + `/EmailTemplateType/GetById/${id}`;;
    const result = await BackendApi.get(url);

    return result;
  },
  getAllEmailTemplateByType: async function (
    typeId: string
  ): Promise<IEmailTemplate[]> {
    let url = BackendConfig.Url() + `/EmailTemplate/GetByType/${typeId}`;
    const result = await BackendApi.get(url);

    return result;
  },

  saveEmailTemplate(id: number, service: IEmailTemplate) {
    return new Promise((resolve, reject) => {
      const url = BackendConfig.Url() + `/EmailTemplate/${id}`;
      BackendApi.post(url, service)
        .then((resp) => resolve(resp))
        .catch((ex) => reject(ex));
    });
  },
};
