import GripVertical from "../components/icons/grip-vertical";
import { $t } from "hooks/i18n";
import { Form } from "react-bootstrap";
import { ServiceRegField } from "../types/edit-service-request";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React from "react";

const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export type ServiceRegFieldsProps = {
    values: ServiceRegField[],
    change(e: ServiceRegField[]): void,
}

const ServiceRegFields = (props: ServiceRegFieldsProps) => {

    const setValue = (checked: boolean, field: string, idx: number) => {
        let tmp = [...props.values];
        (tmp[idx] as any)[field] = checked;
        props.change(tmp);
    }

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const tmp = reorder(
            props.values,
            result.source.index,
            result.destination.index
        );

        props.change(tmp as ServiceRegField[]);
    }

    return <table className="data-table">
            <thead>
                <tr>
                    <th></th>
                    <th>SIERRA väli</th>
                    <th>Väli kuvatud</th>
                    <th>Kohustuslik</th>
                </tr>
            </thead>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="table-body">
                    {(provided: any, snapshot: any) =>
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                            {props.values.map((x, i) =>
                                <Draggable draggableId={x.name} key={x.name} index={i}>
                                    {(provided: any, snapshot: any) => {
                                        return <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{ ...provided.draggableProps.style}} >
                                            <td className="icon"><GripVertical /></td>
                                            <td className="text">{$t(x.title)}</td>
                                            <td className="text">
                                                <Form.Check
                                                    checked={x.visible}
                                                    type="switch"
                                                    onChange={e => setValue(e.target.checked, "visible", i)}
                                                />
                                            </td>
                                            <td className="text">
                                                <Form.Check
                                                    checked={x.required}
                                                    type="switch"
                                                    onChange={e => setValue(e.target.checked, "required", i)}
                                                />
                                            </td>
                                        </tr>
                                    }}
                                </Draggable>
                            )
                            }
                            {provided.placeholder}
                        </tbody>
                    }

                </Droppable>
            </DragDropContext>
        </table>
}

export default ServiceRegFields;