import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import React from "react";
import { AddServiceRequest } from "types/add-service-request";
import PageHeader from "components/page-header";
import FormSection from "components/page/form-section";
import "styles/add-service-page.scss";
import Constants, { ServiceTypes, YesNoChoice } from "Constants";
import { ServicesService } from "services/services-service";
import { hideValidationErrors, showValidationErrors } from "hooks/helpers";
import LocalError from "../../components/form/local-error";

const defaultFormValues: AddServiceRequest = {
    name: "",
    hasRegistration: true,
    type: Constants.Service.Types.national,
    design: Constants.Service.Designs.national,
    regtemplate: Constants.Service.RegistrationTemplates.national,
    mailtemplate: Constants.Service.MailTemplates.national,
    allow_sso: true
}

const AddServicePage = () => {
    const [formData, setFormData] = React.useState<AddServiceRequest>(defaultFormValues);
    const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({});

    const navigate = useNavigate();

    const setField = (val: any, field: keyof AddServiceRequest) => {
        let tmp = { ...formData } as { [key: string]: any };
        tmp[field] = val;
        setFormData(tmp as AddServiceRequest);
    }
    const setBoolField = (val: any, field: keyof AddServiceRequest) => {
        let tmp = { ...formData } as { [key: string]: any };
        tmp[field] = val == "true";
        setFormData(tmp as AddServiceRequest);
    }

    const cancelAction = () => {
        closeForm();
    }

    const submit = () => {
        hideValidationErrors(localErrors, setLocalErrors);
        ServicesService.addService(formData).then((resp) => {
            if (!showValidationErrors(resp, setLocalErrors)) {
                closeForm();
            }
        })
    }

    const closeForm = () => {
        navigate("/services");
	}

    return (
        <div className="add-service-page">
            <PageHeader title="Lisa uus teenus">
                <Col xs={8} className="title-right">
                    <Button variant="primary" onClick={submit}>Salvesta</Button>
                    <Button variant="secondary" onClick={cancelAction}>Loobu</Button>
                </Col>
            </PageHeader>
            <Container className="add-service-content">
                <Form>
                    <FormSection title="Üldandmed" required>
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Teenuse nimetus</Form.Label>
                                    <Form.Control value={formData.name} isInvalid={!!localErrors.name} onChange={e => setField(e.target.value, "name")} required type="input" />
                                    <LocalError message={localErrors.name} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <Form.Group className="mb-3" controlId="type">
                                    <Form.Label>Teenuse liik</Form.Label>
                                    <Form.Select value={formData.type} isInvalid={!!localErrors.type} onChange={e => setField(e.target.value, "type")}>
                                        {ServiceTypes.map(x => <option key={x.name} value={x.name}>{x.title}</option>)}
                                    </Form.Select>
                                    <LocalError message={localErrors.type} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <Form.Group className="mb-3" controlId="registration">
                                    <Form.Label>Teenusele registreerimine lubatud</Form.Label>
                                    <Form.Select value={formData.hasRegistration.toString()} isInvalid={!!localErrors.hasRegistration} onChange={e => setBoolField(e.target.value, "hasRegistration")}>
                                        <option value="true">Jah</option>
                                        <option value="false">Ei</option>
                                    </Form.Select>
                                    <LocalError message={localErrors.hasRegistration} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <Form.Group className="mb-3" controlId="design">
                                    <Form.Label>Teenuse disain</Form.Label>
                                    <Form.Select value={formData.design} isInvalid={!!localErrors.design} onChange={e => setField(e.target.value, "design")}>
                                        {!formData.design ? <option value=""></option> : null}
                                        {ServiceTypes.map(x => <option key={x.name} value={x.name}>{x.title}</option>)}
                                    </Form.Select>
                                    <LocalError message={localErrors.design} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={6} md={4}>
                                <Form.Group className="mb-3" controlId="type">
                                    <Form.Label>Ainulogimine lubatud</Form.Label>
                                    <Form.Select value={formData.allow_sso.toString()} onChange={e => setBoolField(e.target.value, "allow_sso")} isInvalid={!!localErrors.allow_sso}>
                                        {YesNoChoice.map(x => <option key={x.value.toString()} value={x.value.toString()}>{x.text}</option>)}
                                    </Form.Select>
                                    {!!localErrors.allow_sso ? <Form.Control.Feedback type="invalid">{localErrors.allow_sso}</Form.Control.Feedback> : null}
                                </Form.Group>
                            </Col>
                            {formData.hasRegistration ? <Col xs={12} sm={6} md={4}>
                                <Form.Group className="mb-3" controlId="regtemplate">
                                    <Form.Label>Teenuse registreerimisavalduse põhi</Form.Label>
                                    <Form.Select value={formData.regtemplate} isInvalid={!!localErrors.regtemplate} onChange={e => setField(e.target.value, "regtemplate")}>
                                        {!formData.regtemplate ? <option value=""></option> : null}
                                        {ServiceTypes.map(x => <option key={x.name} value={x.name}>{x.title}</option>)}
                                    </Form.Select>
                                    <LocalError message={localErrors.regtemplate} />
                                </Form.Group>
                            </Col> : null}
                            {formData.hasRegistration ? <Col xs={12} sm={6} md={4}>
                                <Form.Group className="mb-3" controlId="mailtemplate">
                                    <Form.Label>Teenuse kirjapõhi</Form.Label>
                                    <Form.Select value={formData.mailtemplate} isInvalid={!!localErrors.mailtemplate} onChange={e => setField(e.target.value, "mailtemplate")}>
                                        {!formData.mailtemplate ? <option value=""></option> : null}
                                        {ServiceTypes.map(x => <option key={x.name} value={x.name}>{x.title}</option>)}
                                    </Form.Select>
                                    <LocalError message={localErrors.mailtemplate} />
                                </Form.Group>
                            </Col> : null}
                        </Row>
                    </FormSection>
                </Form>
            </Container>
        </div>
    );
}

export default AddServicePage;