import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";
import { UserData } from "../types/user-data";

export const AuthService = {
    getUserName: async function (): Promise<string | null> {
        const url = BackendConfig.Url() + `/auth/getname`;
        const result: string = await BackendApi.get(url);
        return result;
    },
    getUser: async function (): Promise<UserData | null> {
        const url = BackendConfig.Url() + `/auth/GetUser`;
        const result: UserData = await BackendApi.get(url);
        return result;
    },
    getLogoutUrl: async function (): Promise<any> {
        const url = BackendConfig.Url() + `/auth/GetLogoutUrl`;
        const result = await BackendApi.get(url);
        return result;
    }, 
};