import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Collapse, Form } from "react-bootstrap";
import horVektor from "../assets/horVektor.svg";
import verVektor from "../assets/verVektor.svg";
import "styles/page-template.scss";
import { hideValidationErrors, showError, showValidationErrors } from "../hooks/helpers";
import { SettingsService } from "../services/settings-service";
import { SettingField, SettingsRequest } from "../types/settings-request";
import FormField from "../components/form/form-field";
import { useNavigate } from "react-router";


const SettingsPage = () => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(true);
    const [formData, setFormData] = React.useState<{ [key: string]: any } | null>(null);
    const [request, setRequest] = React.useState<SettingsRequest | null>(null);
    const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({});
    const [opening, setOpening] = React.useState<Boolean>(true);

    const loadData = () => {
        SettingsService.fetchSettings()
            .then((resp) => {
                setRequest(resp as SettingsRequest);
                let tmp = {} as { [key: string]: any };
                for (let fld of (resp as SettingsRequest).fields) {
                    tmp[fld.key] = fld.value;
				}
                setFormData(tmp);
                setOpening(false);
            })
            .catch(ex => showError(ex));
    }
    const setField = (val: any, field: string) => {
        let tmp = { ...formData } as { [key: string]: any };
        tmp[field] = val;
        setFormData(tmp as SettingsRequest);
    }
    const saveClick = () => {
        hideValidationErrors(localErrors, setLocalErrors);
        let data = { ...request } as SettingsRequest;
        for (let fld of data.fields) {
            fld.value = formData == null ? null : formData[fld.key];
		}
        SettingsService.saveSettings(data)
            .then((resp) => {
                if (!showValidationErrors(resp, setLocalErrors)) {
                    closeForm();
                }
            })
            .catch(ex => showError(ex));
    }
    const closeForm = () => {
        navigate("/");
    }

    React.useEffect(() => {
        loadData();
    }, []);

    if (opening || formData == null || request == null) return null;

  return (
    <Container fluid className="title-header">
      <Container>
        <Row className="title-header pt-3 mb-5">
          <Col className="title-row d-flex align-items-center">
            <h1>Süsteemi seaded</h1>
          </Col>
          <Col className="d-flex align-items-center justify-content-end gap-5">
                      <Button variant="secondary" onClick={() => closeForm()}>Loobu</Button>
                      <Button variant="primary" onClick={() => saveClick()}>Salvesta</Button>
          </Col>
        </Row>
        <Row>
          <div className="d-flex gap-3 mb-4">
            <Button
              className="expand-btn"
              variant="secondary"
              onClick={() => setOpen((prev) => !prev)}
              aria-controls="expand settings"
              aria-expanded={open}
            >
              <img src={horVektor} />
              {!open && <img src={verVektor} />}
            </Button>
            <span className="sub-heading">Üldised seaded</span>
          </div>
          <Collapse in={open}>
            <div className="mb-5">
                          <Form className="form">
                              <Row>
                                  {request.fields.map(x => <FormField
                                      key={x.key}
                                      fld={x}
                                      value={formData[x.key]}
                                      errorMessage={localErrors[x.key]}
                                      disabled={false}
                                      change={e => setField(e, x.key)}
                                  />)}
                              </Row>
                          </Form>
                      </div>
          </Collapse>
        </Row>
      </Container>
    </Container>
  );
};

SettingsPage.displayName = "SettingsPage";

export default SettingsPage;
