import React from "react";
import PageTitle from "components/page/page-title";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { SierraField, SierraFieldRequest } from "../../types/sierra-field";
import { SierraFieldsService } from "../../services/sierra-fields-service";
import { showError, hideValidationErrors, showValidationErrors } from "../../hooks/helpers";
import { FieldTypes } from "../../Constants";


const FieldEditPage = () => {
    const [formData, setFormData] = React.useState<SierraField | null>(null);
    const [opening, setOpening] = React.useState<Boolean>(true);
    const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({});

    let { id } = useParams();
    const navigate = useNavigate();

    const loadData = () => {
        let int_id = id ? parseInt(id) : 0;

        if (!int_id) {
            setFormData({
                id: 0,
                name: "",
                sierraName: "",
                type: "string",
                active: true
            } as SierraField);
            setOpening(false);
            return;
        }
        SierraFieldsService.fetchField(int_id)
            .then((resp) => {
                let respData = resp as SierraFieldRequest;
                let data = { ...respData, choices: respData.choices == null ? null : respData.choices.join("\n") } as SierraField;
                setFormData(data);
                setOpening(false);
            })
            .catch(ex => showError(ex));
    }
    const setField = (val: any, field: string) => {
        let tmp = { ...formData } as { [key: string]: any };
        tmp[field] = val;
        setFormData(tmp as SierraField);
    }

    const closeForm = () => {
        navigate("/fields");
    }
    const saveClick = () => {
        if (formData == null) return;

        let data = {...formData, choices: []} as SierraFieldRequest;
        if (formData.choices)
            data.choices = formData.choices.split("\n").map(x => x.trim()).filter(x => x);
        
        hideValidationErrors(localErrors, setLocalErrors);

        SierraFieldsService.saveField(data)
            .then((resp) => {
                if (!showValidationErrors(resp, setLocalErrors)) {
                    closeForm();
                }
            })
            .catch(ex => showError(ex));
    }

    React.useEffect(() => {
        loadData();
    }, []);

    if (opening || formData == null) return null;
    return (<div>
        <PageTitle title="SIERRA välja muutmine">
            <Button variant="secondary" onClick={closeForm}>Loobu</Button>
            <Button variant="primary" onClick={saveClick}>Salvesta</Button>
        </PageTitle>
        <div>
            <Container>
                <Form className="form-section mt-4">
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Nimi</Form.Label>
                                <Form.Control value={formData.name} onChange={e => setField(e.target.value, "name")} isInvalid={!!localErrors.name} />
                                {!!localErrors.name ? <Form.Control.Feedback type="invalid">{localErrors.name}</Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Group className="mb-3" controlId="sierraName">
                                <Form.Label>Mäpping SIERRga</Form.Label>
                                <Form.Control value={formData.sierraName} onChange={e => setField(e.target.value, "sierraName")} isInvalid={!!localErrors.sierraName} />
                                {!!localErrors.sierraName ? <Form.Control.Feedback type="invalid">{localErrors.sierraName}</Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Group className="mb-3" controlId="type">
                                <Form.Label>Tüüp</Form.Label>
                                <Form.Select value={formData.type} onChange={e => setField(e.target.value, "type")} isInvalid={!!localErrors.type}>
                                    {FieldTypes.map(x => <option key={x.name} value={x.name}>{x.title}</option>)}
                                </Form.Select>
                                {!!localErrors.type ? <Form.Control.Feedback type="invalid">{localErrors.type}</Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col>
                        {formData.type == "multi_choice" ? <Col xs={12} sm={6}>
                            <Form.Group className="mb-3" controlId="type">
                                <Form.Label>Valikud (Kirjuta iga valik eraldi reale)</Form.Label>
                                <Form.Control as="textarea" rows={6} value={formData.choices ?? ""} onChange={e => setField(e.target.value, "choices")} isInvalid={!!localErrors.choices} />
                                {!!localErrors.choices ? <Form.Control.Feedback type="invalid">{localErrors.choices}</Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col> : null}
                        <Col xs={12}>
                            <Form.Check
                                checked={formData.active}
                                type="switch"
                                inline
                                label="Active"
                                isInvalid={!!localErrors.active}
                                onChange={e => setField(e.target.checked, "active")}
                            />
                            {!!localErrors.active ? <div className="local-error">{localErrors.active}</div> : null}
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    </div>);
}

export default FieldEditPage;
