import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";
import { SierraField, SierraFieldRequest } from "../types/sierra-field";

export const SierraFieldsService = {
    fetchFields() {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + `/SierraField/`;
            BackendApi.get(url)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    },
    fetchField(id: number) {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + `/SierraField/${id}`;
            BackendApi.get(url)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    },
    saveField(data: SierraFieldRequest) {
        //TODO temp fix
        data.title = "title";
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + `/SierraField/${data.id}`;
            BackendApi.post(url, data)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    }
};
