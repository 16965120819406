import React from "react";
import PageTitle from "components/page/page-title";
import { Container, Button, Table } from "react-bootstrap";
import editIcon from "assets/pencil.svg";
import { SierraField } from "../../types/sierra-field";
import { SierraFieldsService } from "../../services/sierra-fields-service";
import { useNavigate } from "react-router-dom";
import { FieldTypes } from "../../Constants";

const formatType = (t: string) => {
    let fieldType = FieldTypes.find(x => x.name === t);
    return fieldType == null ? t : fieldType.title;
}

const showError = (ex: any) => {
    console.error(ex);
}

const FieldsListPage = () => {
    const navigate = useNavigate();

    const [rows, setRows] = React.useState<SierraField[]>([]);

    const loadData = () => {
        SierraFieldsService.fetchFields()
            .then((resp) => {
                setRows(resp as SierraField[]);
            })
            .catch(ex => showError(ex));
    }
    const newClick = () => {
        navigate("/fields/0");
    }
    const editClick = (id: number) => {
        navigate(`/fields/${id}`);
    }

    React.useEffect(() => {
        loadData();
    }, []);

    return (<div>
        <PageTitle title="SIERRA väljad">
            <Button variant="primary" onClick={newClick}>Uus väli</Button>
        </PageTitle>
        <div>
            <Container>
                <Table className="services-table mt-3">
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Mäpping SIERRAga</th>
                            <th>Tüüp</th>
                            <th>Aktiivne</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map(x => <tr key={x.id}>
                            <td>{x.name}</td>
                            <td>{x.sierraName}</td>
                            <td>{formatType(x.type)}</td>
                            <td>{x.active ? "Jah" : "Ei"}</td>
                            <td>
                                <Button variant="link" className="btn-icon" onClick={() => editClick(x.id)}>
                                    <img src={editIcon} />
                                </Button>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </Container>
        </div>
    </div>);
}

export default FieldsListPage;
