import * as React from "react";
import "../styles/loader.scss";

export default function Loader() {

  return (
    <div id="loader" className="loader-bg">
      <div className="loader-clock">
        <div className="loader-arm" />
        <div className="loader-arm loader-arm-short" />
        <div className="loader-middle" />
      </div>
    </div>
  );
}

Loader.displayName = "Loader"; //for chrome to show name instead of anonymous.
