import React from "react";
import Loader from "./components/loader";
import AppStart from "./app-start";
import { MainPage } from "./pages/main-page";
import { BrowserRouter, Route } from "react-router-dom";
import { Routes } from "react-router";
import ServiceEditPage from "pages/services/service-edit-page";
import ServicesPage from "pages/services/services-page";
import TopMenu from "components/top-menu/top-menu";
import AddServicePage from "pages/services/add-service-page";
import { UserContextProvider } from "contexts/userContext";
import FieldsListPage from "pages/sierra_fields/fields-list-page";
import FieldEditPage from "pages/sierra_fields/field-edit-page";
import SettingsPage from "pages/settings-page";
import TranslationsPage from "pages/translation/translations-page";
import EmailTemplatesPage from "pages/email-templates/email-templates-page";
import EmailTemplateEditPage from "pages/email-templates/email-template-edit-page";
import UsersPageBase from "./pages/users-page-base";
import { AuthService } from "./services/auth-service";
import { UserData } from "./types/user-data";
import { Container, Row, Col } from "react-bootstrap";
import ActivityLogPage from "pages/activitylog/activity-log-page";
import StatisticsPage from "pages/statistics/statistics-page";
import SpecialRightsPage from "pages/special-rights/special-rights-page";

function App() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [user, setUser] = React.useState<UserData | null>(null);
    const logout = async () => {
      const url = await AuthService.getLogoutUrl();
      if (url)
        location.href = url;
    }
    const loadPage = async () => {
        var path = window.location.pathname;
        if (path.startsWith("/auth/logout")) {
            logout();
            return;

        }

        const user = await AuthService.getUser();
        setLoading(false);
        if (!user)
            return;
        setUser(user);
    };

    React.useEffect(() => {
        loadPage();
    }, []);

    if (loading) return null;

    const renderPageContent = () => {
        if (!user)
            return null;
        setPageTitle();
        return <div className="main-page theme-rara">
            <TopMenu user={user}></TopMenu>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/services/add" element={<AddServicePage />} />
                <Route path="/services/:id" element={<ServiceEditPage user={user} />} />
                <Route path="/services" element={<ServicesPage user={user} />} />
                <Route path="/emailtemplates" element={<EmailTemplatesPage />} />
                <Route path="/emailtemplates/:id" element={<EmailTemplateEditPage />} />
                <Route path="/fields/:id" element={<FieldEditPage />} />
                <Route path="/fields" element={<FieldsListPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/users/*" element={<UsersPageBase />} />
                <Route path="/logs" element={<ActivityLogPage user={user} />} />
                <Route path="/translations" element={<TranslationsPage user={user} />} />
                <Route path="/statistics" element={<StatisticsPage user={user} />} />
                <Route path="/special-rights" element={<SpecialRightsPage/>}/>
            </Routes>
        </div>;
    }

    const setPageTitle = () => {
        if (process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "test") {
          document.title = document.title + process.env.REACT_APP_ENV.toUpperCase();
        }
    };

    const renderNoAccess = () => {
        return <div className="main-page theme-rara">
            <TopMenu user={user}></TopMenu>
            <Container>
                <Row>
                    <Col>
                        <h3>Puudub juurdepääs teenusele</h3>
                    </Col>
                </Row>
            </Container>
        </div>;
    }

    return (
        <UserContextProvider>
            <div className="App">
                <AppStart />
                <Loader />
                <BrowserRouter>
                    <div className="page-content">
                        {!user ? renderNoAccess() : renderPageContent()}
                    </div>
                </BrowserRouter>
            </div>
        </UserContextProvider>
    );
}

export default App;
