import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";
import { ClassifierDto } from "../dto/classifier-dto";

export const ClassifierService = { 
  async fetchAllClassifiers(): Promise<ClassifierDto[]> {
    const url = BackendConfig.Url() + `/Classifier/all`;
    const result = await BackendApi.get(url);    
    return result;
  },
};
