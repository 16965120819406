import React, { useState } from "react";
import { Col, Container, Row, Table, Form} from "react-bootstrap";
import Constants, { DefaultLanguage, UserRights } from "Constants";
import searchIcon from "assets/search.svg";
import TranslationEditor from "pages/translation/components/translation-editor";
import "styles/page-template.scss";
import { TranslationService } from "services/translation-service";
import { ITranslation } from "./translation";
import Pagination from "components/pagination/pagination";
import { UserData } from "../../types/user-data";

type TranslationsPageProps = {
    user: UserData
}

const TranslationsPage = (props: TranslationsPageProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [translations, setTranslations] = React.useState<Array<ITranslation[]>>([]);
  const [translationsPerPage, setTranslationsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  if (!UserRights.isAdmin(props.user.rights)) return null;

  const alltranslationsCount = translations.length;
  const lastTranslationNumber = currentPage * translationsPerPage;
  const firstTranslationIndex = lastTranslationNumber - translationsPerPage;
  const limitedTranslations = translations.slice(
    firstTranslationIndex,
    lastTranslationNumber
  );

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    loadTranslations();
  };

  const loadPage = async () => {
    loadTranslations();
  }

  const saveChanges = (data : ITranslation[]) => {
     if(data?.length === 0)
          return
     let key = data[0].key
     let index!: number;
     translations.every((x, i) => {
          if(x.some(x1 => x1.key === key)){
            index = i;
            return false;
          }
          return true;

     })
     if(index !== undefined){
       let ne = [...translations];
       ne[index] = data
       setTranslations(ne);
       data.forEach(async x => await TranslationService.saveTranslation(x.id,x))
     }
     
  }
  
  function groupBy(list:any, keyGetter:any) {
    const map = new Map();
    list.forEach((item:any) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
  const loadTranslations = async () => {
    let groupedTranslations = groupBy(await TranslationService.searchTranslation(searchTerm), (trans: { key: any; }) => trans.key);;
    let _translations = Array.from( groupedTranslations.values()); 
    let filteredTranslations = _translations.filter(function(e){
      return e.find((x:any) => x.language === DefaultLanguage) 
    })
    setTranslations(filteredTranslations);
  }

  React.useEffect(() => {
    loadPage();
  }, []);

  return (
    <Container fluid className="title-header">
      <Container>
        <Row className="title-header pt-3 mb-5">
          <Col xs={4} className="title-row d-flex align-items-center">
            <h1>Tõlked</h1>
          </Col>
          <Col xs={8} className="d-flex align-items-center">
            <Form onSubmit={handleSearchSubmit} className="search-form">
              <span className="search-icon">
                <img src={searchIcon} alt="Search icon" />
              </span>
              <Form.Control
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Otsi tõlkeid"
              />
            </Form>
          </Col>
        </Row>
        <Table className="table">
          <thead>
            <tr className="table-header">
              <th>Kood</th>
              <th>Eesti keel</th>
              <th></th>
            </tr>
          </thead>
          <tbody>         
            {limitedTranslations           
              .map((el, index) => {
                return <TranslationEditor key={index}  translations={el} saveChanges={saveChanges} />;
              })
            }
          </tbody>
        </Table>
        {translations.length > 0 && ( 
        <Pagination
          itemsCount={alltranslationsCount}
          itemsPerPage={translationsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          alwaysShown={false}
        />)
        }       
      </Container>
    </Container>
  );
};

TranslationsPage.displayName = "TranslationsPage";

export default TranslationsPage;
