import { Table, Button } from "react-bootstrap";
import editIcon from "assets/pencil.svg";
import "styles/email-template-table.scss";
import { useNavigate } from "react-router-dom";
import { IEmailTemplateType } from "../email-template-type";
import { $t } from "hooks/i18n";



export type EmailTemplateTableProps = {
  services: IEmailTemplateType[];
  role: string;
}

const EmailTemplateTable = (props: EmailTemplateTableProps) => {

  const navigate = useNavigate();

  const editEmailTemplates = (id: number) => {
    navigate(`/emailtemplates/${id}`);
  }

  return (
    <Table className="email-template-table">
      <thead>
        <tr>
          <th>{$t("Email Name")}</th>
          <th>{$t("Service")}</th>
          <th>{$t("Template")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody className="email-template-table-body">
        {
          !!props.services &&
          props.services.map(x => (
            <tr key={x.id}>
              <td>{x.description}</td>
              <td>{x.serviceName}</td>
              <td>{x.mailType}</td>
              <td className="icon-cell">
                <Button variant="link" className="btn-icon" onClick={() => editEmailTemplates(x.id)}>
                    <img src={editIcon} />
                </Button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
}

export default EmailTemplateTable;