
export type ServiceAuth = {
  authType: string
}

export type ApplicationData = {
  id: number,
  auth_type: string,
  app_id: string,
  xml_param_name: string,
  servicename_param: string,
  return_url: string,
  unregister_url: string,
  unregistered_url: string,
  unregister_auth_header: string,
  sierra_code: string,
  logging_out: string,
  logging_url: string,
  auth_header_secret: string,
  is_mobile_app: boolean,
  application_saml_config_data?: ApplicationSamlConfigData
}

export type ApplicationSamlConfigData = {
  id: number,
  entity_id: string,
  allowed_scopes: string,
  nameid_format: string,
  authn_request_signed: boolean,
  want_assertions_signed: boolean,
  binding: string,
  acs_location: string,
  metadata_location: string,
  logout_location: string,
  application_id: number,
}

export type Language = {
  id: number,
  locale: string
}

export type EditServiceRequest = {
  id: Number,
  name: string;
  type: string;
  reg_enabled: boolean,
  design: string,
  reg_template: string,
  mail_template: string,
  service_auths: string[];
  service_reg_fields: ServiceRegField[];
  reg_application: Boolean,
  reg_signature: Boolean,
  reg_to_dhs: Boolean,
  terms_signature: Boolean,
  cancel_enabled: Boolean,
  cancel_signature: Boolean,
  cancel_to_dhs: Boolean,
  data_expire_days: number,
  user_changes_to_dhs: Boolean,
  applications: ApplicationData[],
  sierra_prefix: string,
  sierra_group: string,
  sierra_underage_group: string,
  service_terms: File,
  privacy_policy: File,
  temp: string
  service_files?: ServiceFile[],
  email_config: ServiceEmailConfig,
  service_locales: number[],
  all_locales: Language[],
  system_notification_enabled: boolean,
  system_notification: Translations,
  info_notification_enabled: boolean,
  info_notification: Translations,
  allow_sso: Boolean
}

export type ServiceFile =
  {
    id: number,
    name: string,
    type: string
  }

export type ServiceRegField = {
  name: string,
  title: string,
  visible: boolean,
  required: boolean,
}

export enum ServiceFileType {
  ServiceTerms = 'ServiceTerms',
  PrivacyPolicy = 'PrivacyPolicy'
}
export type ServiceEmailConfig =
  {
    id?: number,
    emailSmtp: string,
    emailSmtpPort?: number,
    emailFromEmail?: string,
    emailFromPassword?: string,
    emailFromName?: string
  }
export type Translations = {
  et?: string,
  en?: string,
  ru?: string
}
