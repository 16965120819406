import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import logoRara from "../../assets/rara-logo-simple.svg";
import "./top-menu.scss"
import AccountInfo from "./components/account-info";
import React from "react";
import { UserData } from "../../types/user-data";
import { UserRights } from "../../Constants";

type MenuItem = {
  text: string;
  url: string;
  orderPriority: number;
}

const commonMenuItems: MenuItem[] = [];
const peakasutajaMenuItems: MenuItem[] = [
    ...commonMenuItems,
    {
        text: "Teenused",
        url: "/services",
        orderPriority: 1
    },
    {
        text: "Kirjad",
        url: "/emailtemplates",
        orderPriority: 1
    },
    {
        text: "Statistika",
        url: "/statistics",
        orderPriority: 3
    },
    {
        text: "Logid",
        url: "/logs",
        orderPriority: 3
    }
];

const menetlejaMenuItems: MenuItem[] = [
    ...commonMenuItems,
    {
        text: "Taotlused",
        url: "/special-rights",
        orderPriority: 1
    },
    {
        text: "Statistika",
        url: "/statistics",
        orderPriority: 3
    },
    {
        text: "Logid",
        url: "/logs",
        orderPriority: 3
    }
];

const adminMenuItems: MenuItem[] = [
    ...peakasutajaMenuItems,
    {
        text: "Tõlked",
        url: "/translations",
        orderPriority: 2
    },
    {
        text: "Seaded",
        url: "/settings",
        orderPriority: 2
    },
    {
        text: "Taotlused",
        url: "/special-rights",
        orderPriority: 2
    },
    {
        text: "Kasutajad",
        url: "/users",
        orderPriority: 2
    },
    {
        text: "SIERRA väljad",
        url: "/fields",
        orderPriority: 3
    }
];

type TopMenuProps = {
    user: UserData | null
}

const TopMenu = (props: TopMenuProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const getMenuItems = (rights: number): MenuItem[] => {
        switch (rights) {
            case UserRights.ADMIN:
                return adminMenuItems;
            case UserRights.MAIN_USER:
                return peakasutajaMenuItems;
            case UserRights.MENETLEJA:
                return menetlejaMenuItems;
            default:
                return commonMenuItems;
        }
    }

    return (
        <Navbar>
            <Container className="justify-content-between align-items-center">
                <Navbar.Brand href="/">
                    <img src={logoRara} />
                </Navbar.Brand>
                {props.user ?<Nav className="m-auto" activeKey={location.pathname}>
                    {
                        getMenuItems(props.user.rights).sort((x, y) => x.orderPriority - y.orderPriority)
                            .map(x => <Nav.Link key={x.text} className="menu-item" onClick={() => navigate(x.url)}>{x.text}</Nav.Link>)
                    }
                </Nav> : null}
                {props.user ? <AccountInfo user={props.user} /> : null}
            </Container>
        </Navbar>
    )
}

TopMenu.displayName = "TopMenu";

export default TopMenu;
