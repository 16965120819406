import React from "react";
import { useLocation } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import "styles/page-template.scss";
import { UserData } from "../types/user-data";
import { UserRights } from "../Constants";
import { useParams, useNavigate } from "react-router-dom";
import { hideValidationErrors, showError, showValidationErrors } from "../hooks/helpers";
import { UserService } from "../services/user-service";
import { ServicesService } from "../services/services-service";
import { ServiceData } from "../types/service-data";

type SelectOption = {
  value: number;
  label: string;
};

const roles: SelectOption[] = [
    { value: UserRights.MAIN_USER, label: "Peakasutaja" },
    { value: UserRights.ADMIN, label: "Administraator" },
    { value: UserRights.MENETLEJA, label: "Menetleja" }
];

const defaultFormValues: UserData = {
    id: 0,
    name: "",
    personCode: "",
    rights: UserRights.MAIN_USER,
    isActive: true,
    services: []
};

type EditUserPageProps = {
    allServices: ServiceData[]
};

const EditUserPage = (props: EditUserPageProps) => {
    const [formData, setFormData] = React.useState<UserData | null>(null);
    const [formServices, setFormServices] = React.useState<SelectOption[] | null>(null);
    const [formRole, setFormRole] = React.useState<SelectOption | null>(null);
    const [allServices, setAllServices] = React.useState<SelectOption[] | null>(null);
    const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({});

    let { id } = useParams();
    const navigate = useNavigate();

    const setField = (val: any, field: keyof UserData) => {
        if (formData != null) {
            let newData: UserData = { ...formData, [field]: val };
            setFormData(newData);
        }
    };
    const setFormService = (serv: MultiValue<SelectOption>) => {
        setFormServices([...serv]);
	}

    const closeForm = () => {
        navigate("/users");
	}

    React.useEffect(() => {
        const loadUser = () => {
            if (!id) {
                showError("Puudub id parameeter");
                return;
            }

            let id_int = parseInt(id as string);
            let allServ = props.allServices.map((x: ServiceData) => ({ value: x.id, label: x.name } as SelectOption));
            setAllServices(allServ);

            if (id_int == 0) {
                setFormRole(roles.find(x => x.value == defaultFormValues.rights) ?? null);
                setFormData({ ...defaultFormValues });
            }
            else {
                UserService.fetchUser(id_int)
                    .then((userResp: any) => {
                        setFormRole(roles.find(x => x.value == userResp.rights) ?? null);
                        setFormServices(allServ?.filter(x => userResp.services.indexOf(x.value) >= 0) ?? []);
                        setFormData(userResp as UserData);
                    })
                    .catch(ex => showError(ex));
            }
		}
        loadUser();
    }, []);

    if (formData == null)
        return null;

    const saveClick = () => {
        if (!id || !formData) {
            return;
        }

        let data = { ...formData } as UserData;
        if (formRole != null)
            data.rights = formRole.value;
        if (formServices != null)
            data.services = formServices.map(x => x.value);

        hideValidationErrors(localErrors, setLocalErrors);
        UserService.saveUser(data)
            .then((resp) => {
                if (!showValidationErrors(resp, setLocalErrors)) {
                    closeForm();
                }
            })
            .catch(ex => showError(ex));
    }

    return (
        <Container>
            <Row className="title-header pt-3 mb-5">
                <Col className="title-row d-flex align-items-center">
                    <h1>Kasutajad</h1>
                </Col>
                <Col className="d-flex align-items-center justify-content-end gap-5">
                    <Button variant="secondary" onClick={closeForm}>Loobu</Button>
                    <Button onClick={saveClick}>Salvesta</Button>
                </Col>
            </Row>
            <Row>
                <div className="d-flex gap-3 mb-4">
                    <span className="sub-heading">Kasutaja</span>
                </div>
                <Form className="form">
                    <Row>
                        <Col xs={4}>
                            <Form.Group controlId="controlPersonalNumber">
                                <Form.Label>Isikukood</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={formData.personCode}
                                    isInvalid={!!localErrors.personCode}
                                    onChange={(e) => setField(e.target.value, "personCode")}
                                />
                                {!!localErrors.personCode ? <Form.Control.Feedback type="invalid">{localErrors.personCode}</Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col>
                        <Col xs={4} className="position-relative">
                            <Form.Group controlId="controlServices">
                                <Form.Label>Teenus</Form.Label>
                                <Select
                                    className="selectMulti"
                                    value={formServices}
                                    onChange={(option) => setFormService(option)}
                                    options={allServices ?? []}
                                    isMulti
                                    classNamePrefix="select"
                                    noOptionsMessage={() => "Valikuvõimalusi pole"}
                                    placeholder="Vali teenused"
                                />
                                {!!localErrors.services ? <div className="local-error multi-select-fix">{localErrors.services}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Group controlId="controlRole">
                                <Form.Label>Roll</Form.Label>
                                <Select
                                    className="select"
                                    value={formRole}
                                    onChange={(option) => setFormRole(option)}
                                    options={roles}
                                    classNamePrefix="select"
                                    placeholder="Vali rolli"
                                />
                                {!!localErrors.rights ? <div className="local-error">{localErrors.rights}</div> : null}
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </Container>
    );
};

EditUserPage.displayName = "EditUserPage";

export default EditUserPage;
