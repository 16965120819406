import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export type EmbedContent = {
    isImage: boolean,
    dataUrl: string,
    fileName: string
}

export function EmbedModal(props: {
    isOpen: boolean,
    src: EmbedContent[],
    onClose: () => void
}): JSX.Element {
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const embedElement = document.querySelector("embed");
        if (embedElement)  {
            setLoading(true)
            embedElement.onload = () => {
                setLoading(false)
            }
        }

    }, [props.src])

    return (
        <Modal show={props.isOpen}
            onHide={props.onClose} 
            size="xl" 
            scrollable={true}
        >
            <Modal.Body>
                {loading && 
                    <>Loading...</>
                }

                {!loading &&
                    <>
                        {props.src.map((x, idx) => {
                            if (x.isImage) {
                                return (
                                    <div key={idx}>
                                        <p>{x.fileName}</p>
                                        <embed src={x.dataUrl} width={"100%"} height={"100%"} style={{pointerEvents: "none"}}/>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={idx}>
                                        <p>{x.fileName}</p>
                                        <iframe id="custom-iframe" src={x.dataUrl} style={{height: "1000px", width: "100%"}} /> 
                                    </div>
                                )
                            }
                        })}

                    </>
                }
            </Modal.Body>
        </Modal>
    )
}
