import { Form } from "react-bootstrap";


type LocalErrorProps = {
	message: string
}

const LocalError = (props: LocalErrorProps) => {

	if (!props.message) return null;
	return (<Form.Control.Feedback type="invalid">{props.message}</Form.Control.Feedback>);
}

export default LocalError;
