import BackendApi from "backend-api";
import BackendConfig from "backend-config";
import { SpecialRightsApplication } from "pages/special-rights/special-rights-application-models";


export const SpecialRightsService = {
    getAllApplications: async function (): Promise<SpecialRightsApplication[]> {
        let url = `${BackendConfig.Url()}/SpecialRights`;
        return await BackendApi.get(url);
    },
    acceptApplication: async function (id: number, expirationDate?: string): Promise<any> {
        let url = `${BackendConfig.Url()}/SpecialRights/accept/${id}`;
        const params = new URLSearchParams();
        if (!!expirationDate) {
            params.append("expirationDate", expirationDate);
        }
        url = `${url}?${params}`;
        return await BackendApi.post(url);
    },
    rejectApplication: async function (id: number, rejectionReason: string): Promise<any> {
        let url = `${BackendConfig.Url()}/SpecialRights/reject/${id}`;

        const params = new URLSearchParams();
        params.append("reason", rejectionReason);

        url = `${url}?${params}`;
        return await BackendApi.post(url);
    },
    createFileLink(id: number, fileName: string): string {
        return `${BackendConfig.Url()}/SpecialRights/files/${id}/${fileName}`;
    },
    createDownloadFileLink(id: number, fileName: string): string {
        return `${BackendConfig.Url()}/SpecialRights/file/${id}/${fileName}`;
    },
    getDataUrl: async function(url: string): Promise<{isImage: boolean, dataUrl: string, fileName: string}[]> {
        return await BackendApi.get(url);
    }
}
