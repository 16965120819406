import { Container } from "react-bootstrap";

type PageTitleProps = {
    title: string,
    children: React.ReactNode | null
}

const PageTitle = (props: PageTitleProps) => {
    return (<div className="page-title">
        <Container>
            <div className="cont">
                <div><h1>{props.title}</h1></div>
                <div>{!props.children ? null : props.children}</div>
            </div>
        </Container>
    </div>);
}

export default PageTitle;
