import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Col,
    Container,
    Row,
    Table,
    Form,
    Pagination,
} from "react-bootstrap";

import { UserRights } from "Constants";
import editIcon from "assets/pencil.svg";
import "styles/page-template.scss";
import { UserService } from "../services/user-service";
import { showError } from "../hooks/helpers";
import { ServiceData } from "../types/service-data";
import { UserDataResultSet } from "../types/user-data";
import ConfirmModal from "components/modals/confirm-modal";


type UsersPageProps = {
    allServices: ServiceData[]
};

const createPages = (res: UserDataResultSet, setPages: Function) => {
    let pages = [];
    for (var i = 1; i <= Math.ceil(res.totalItems / res.pageSize); i++) {
        pages.push(i);
	}
    setPages(pages);
}

const UsersPage = (props: UsersPageProps) => {
    const [users, setUsers] = useState<UserDataResultSet | null>(null);
    const [checkedIds, setCheckedIds] = useState([] as number[]);
    const [confirmModal, setConfirmModal] = React.useState<{ [key: string]: any }>({ open: false });
    const [pages, setPages] = useState<number[]>([1]);
    const [page, setPage] = useState<number>(1);
    const pageSize = 25;

    const navigate = useNavigate();

    const editUser = (id: number) => {
        navigate(`/users/${id}`);
    };

    const deleteUsers = () => {
        if (checkedIds.length == 0) return;
        setConfirmModal({ open: true });
    }

    const deleteUsersConfirmed = () => {
        setConfirmModal({ open: false });
        UserService.deleteUsers(checkedIds)
            .then(() => {
                loadData(1);
            })
            .catch(ex => showError(ex));
    }

    const loadData = (loadPage: number) => {
        setPage(loadPage);
        UserService.fetchUsers(loadPage, pageSize)
            .then((resp: any) => {
                setUsers(resp as UserDataResultSet);
                createPages(resp as UserDataResultSet, setPages);
            })
            .catch(ex => showError(ex));
    }

    React.useEffect(() => {
        loadData(1);
    }, []);

    if (users == null)
        return null;

    const pageClick = (newPage: number) => {
        if (newPage < 1)
            newPage = 1;
        if (newPage > pages.length)
            newPage = pages.length;
        loadData(newPage);
	}

    return (
        <Container>
            <Row className="title-header pt-3 mb-5">
                <Col className="title-row d-flex align-items-center">
                    <h1>Kasutajad</h1>
                </Col>
                <Col className="d-flex align-items-center justify-content-end gap-5">
                    <Button variant="secondary" onClick={deleteUsers}>
                        Kustuta valitud
                    </Button>
                    <Button onClick={() => editUser(0)}>Lisa uus</Button>
                </Col>
            </Row>
            <Table className="table">
                <thead>
                    <tr className="table-header">
                        <th>Nimi</th>
                        <th>Teenus</th>
                        <th>Roll</th>
                        <th>Vali</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.items.map((el) => (
                            <tr key={el.id}>
                            <td>{el.name}</td>
                                <td>{props.allServices.filter(x => el.services.indexOf(x.id) >= 0).map(x => x.name).join(", ")}</td>
                                <td>{UserRights.format(el.rights)}</td>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        inline
                                        onChange={(event: any) => {
                                            if (event.target.checked)
                                                setCheckedIds((prev) => [...prev, el.id]);
                                            else
                                                setCheckedIds((prev) => [
                                                    ...prev.filter((x) => x !== el.id),
                                                ]);
                                        }}
                                    />
                                </td>
                                <td>
                                    <Button
                                        variant="link"
                                        className="btn-icon"
                                        onClick={() => editUser(el.id)}
                                    >
                                        <img src={editIcon} />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            {pages.length <= 1 ? null : <Pagination className="pagination pb-5">
                <Pagination.Prev className="prev" onClick={() => pageClick(page - 1)} />
                {pages.map(x => <Pagination.Item active={page == x} onClick={() => pageClick(x)}>{x}</Pagination.Item>) }
                <Pagination.Next className="next" onClick={() => pageClick(page + 1)} />
            </Pagination>}
            {confirmModal.open ? <ConfirmModal
                show={confirmModal.open}
                title=""
                message="Kas oled kindel, et soovid valitud kasutajad kustutada?"
                onCancel={() => setConfirmModal({ open: false })}
                onOk={() => deleteUsersConfirmed()}
            /> : null}
        </Container>
    );
};

UsersPage.displayName = "UsersPage";

export default UsersPage;
