import BackendApi from "backend-api";
import BackendConfig from "backend-config";
import { IEmailTemplate } from "pages/email-templates/email-template";
import { IEmailTemplateType } from "pages/email-templates/email-template-type";
import { ITranslation } from "pages/translation/translation";
import { isBlank, isNotBlank } from "utils/string-utils";

export const TranslationService = {
  
  searchTranslation: async function (searchTerm: string): Promise<ITranslation[]> {
    let url = BackendConfig.Url() + `/Translation/Search`;
    if(isNotBlank(searchTerm)){
      url = url + `/${searchTerm}`;
    }
    const result = await BackendApi.get(url);

    return result;
  },
  
  saveTranslation(id: number, translation: ITranslation) {
    return new Promise((resolve, reject) => {
      const url = BackendConfig.Url() + `/Translation/${id}`;
      BackendApi.post(url, translation)
        .then((resp) => resolve(resp))
        .catch((ex) => reject(ex));
    });
  },
};
