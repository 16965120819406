import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import useIsMobile from "hooks/useIsMobile";

//TODO
export type MainPageProps = {
  //session: SessionInfo
}

function MainPage(props: MainPageProps) {
  const [loading, setLoading] = React.useState<boolean>(true);

  const isMobile = useIsMobile();

  const loadPage = async () => {
    setLoading(false);
  }

  React.useEffect(() => {
    loadPage();
  });

  if (loading) return null;
  return (    
      <Container>
      </Container>
  );
}
MainPage.displayName = "MainPage"; //for chrome to show name instead of anonymous.
export {
  MainPage
};
