import PageHeader from "components/page-header";
import React, { useState } from "react";
import { Button, Col, Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "styles/special-rights-page.scss";
import SpecialRightsApplicationEdit from "./components/special-rights-application-edit";
import { SpecialRightsApplication } from "./special-rights-application-models";
import { SpecialRightsService } from "services/special-rights-service";

const SpecialRightsPage = () => {
    const [applications, setApplications] = useState<SpecialRightsApplication[]>();
    const navigate = useNavigate();

    const loadPage = async () => {
        loadApplications();
    }

    const loadApplications = async () => {
        setApplications(await SpecialRightsService.getAllApplications());
    }

    const openDhs = () => {
        window.open("https://dhs.rahvusraamatukogu.ee/", "_blank");
    }

    const headerElements = (
        <Col xs={8} className="title-right">
            <Button variant="primary" onClick={openDhs}>Dokumendi haldus</Button>
        </Col>
    );

    React.useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
            <div className="special-rights-page">
                <PageHeader title={'Taotlused'} children={headerElements}/>
                <div className="special-rights-content">
                    <Container>
                        <Table className="table">
                            <thead>
                                <tr className="table-header">
                                    <th>Dokument</th>
                                    <th>Nimi</th>
                                    <th>Isikukood</th>
                                    <th>Taotluse esitamise aeg</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                { applications?.map((el, index) => {
                                    return <SpecialRightsApplicationEdit key={index} id={el.id} fileLinks={el.documentLinks} name={el.name} 
                                        personCode={el.personCode} submitDate={el.submitDate} />
                                })}
                            </tbody>
                        </Table>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default SpecialRightsPage;
