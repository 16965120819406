import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";

export const ServiceFileService = {
  downloadServicefile: async function (serviceFileId: number, fileName: string): Promise<any> {
    const url = BackendConfig.Url() + "/ServiceFile/"+serviceFileId;
    BackendApi.getAndDownload(url, fileName);

  },

  uploadServicefiles: async function (serviceId: string, formData: FormData): Promise<boolean> {
    const url = BackendConfig.Url() + "/ServiceFile/" + serviceId + "/files";
    const result: any = await BackendApi.upload(url, formData);

    return result?.success === true;
  },
  deleteServicefile: async function (serviceFileId: number): Promise<boolean> {
    const url = BackendConfig.Url() + "/ServiceFile/delete/" + serviceFileId;
    const result: boolean = await BackendApi.post(url);

    return result;
  },
};