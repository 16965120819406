
export const langOrder = ["et", "en", "ru"];
export const langSort = (a: any, b: any) => {
    let av = langOrder.indexOf(a.languageName);
    if (av == -1) av = 100;
    let bv = langOrder.indexOf(b.languageName);
    if (bv == -1) bv = 100;

    if (av < bv) return -1;
    if (av > bv) return 1;
    return 0;
};
