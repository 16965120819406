import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";
import { UserData } from "../types/user-data";

export const UserService = {
    fetchUsers(page: number, pageSize: number) {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + `/User/?page=${page}&pageSize=${pageSize}`;
            BackendApi.get(url)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    },
    fetchUser(id: number) {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + `/User/${id}`;
            BackendApi.get(url)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    },
    saveUser(data: UserData) {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + `/User/${data.id}`;
            BackendApi.post(url, data)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    },
    deleteUsers(idList: number[]) {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + `/User/`;
            BackendApi.delete(url, {idList})
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
	}
};
