import BackendApi from "backend-api"
import BackendConfig from "backend-config"
import { SettingsRequest } from "../types/settings-request";

export const SettingsService = {
    fetchSettings: async function (): Promise<any | null> {
        let url = BackendConfig.Url() + "/Setting";
        const result = await BackendApi.get(url)
        return result;
    },
    saveSettings: async function (data: SettingsRequest) {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + "/Setting"
            BackendApi.post(url, data)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    }
}
