import React, { useState } from "react";
import "./account-info.scss";
import avatar from "../../../assets/avatar.svg";
import { UserData } from "../../../types/user-data";
import { UserRights } from "../../../Constants";
import { AuthService } from "../../../services/auth-service";

type AccountInfoProps = {
  user: UserData
}

const GetRole = (rights: number) => {
  if (rights == UserRights.ADMIN)
    return "Administraator";
  if (rights == UserRights.MAIN_USER)
    return "Peakasutaja";
  if (rights == UserRights.MENETLEJA)
    return "Menetleja";
  return "";
}




const AccountInfo = (props: AccountInfoProps) => {

  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);

  const logout = async () => {
    const url = await AuthService.getLogoutUrl();
    if (url)
      location.href = url;
  }

  return (
    <>
     
      {
       
        <div className={optionsVisible ? "account-info d-flex account-info-options" :"account-info d-flex" } onClick={() => setOptionsVisible(!optionsVisible)}>
           
              <div className="d-flex flex-column justify-content-center">
                <span className="user-name justify-content-end">{props.user.name}</span>
              <span className="account-role justify-content-end">{GetRole(props.user.rights)}</span>
              {
                optionsVisible &&
              <>
                <div className="info-option" onClick={logout}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" role="button" width="16" height="16" className="mr-2"><path d="M23.5 12h-12m8 4 4-4-4-4m-10-5.5H17a.5.5 0 0 1 .5.5v2.5m0 13V21a.5.5 0 0 1-.5.5H9.5m-9-.277a.5.5 0 0 0 .392.488l8 1.777A.5.5 0 0 0 9.5 23V1a.5.5 0 0 0-.608-.488l-8 1.778a.5.5 0 0 0-.392.488Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.5 12a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0-3 0Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  {' '}Logout
                </div>
                </>
              }             

              </div>
              <div className="account-icon">
                <img src={avatar} />
              </div>
         
        </div> 
      }
    </>
  )
}

export default AccountInfo;
