import BackendConfig from "../backend-config";
import BackendApi from "../backend-api";
import { EditServiceRequest } from "../types/edit-service-request";
import { ServiceFileService } from "./service-file-service";

export const ServiceService = {

  getSamlPublicCertUrl() {
    return BackendConfig.Url() + "/Service/saml/cert";
  },

    fetchService(serviceId: string) {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + `/service/${serviceId}`;
            BackendApi.get(url)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
		});
    },
    saveService(serviceId: string, service: { [key: string]: any }) {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + `/Service/${serviceId}`;
            BackendApi.post(url, service)
                .then(resp => {
                    //TODO better way to do this?
                    const formData = new FormData();
                    formData.set("service_terms", service.service_terms);
                    formData.set("privacy_policy", service.privacy_policy);
                    ServiceFileService.uploadServicefiles(serviceId, formData)                    
                      .then(fileResp => resolve(resp))
                      .catch(ex => reject(ex));
                })
                .catch(ex => reject(ex));
		});
        //after success post file changes as well
	}
};
