import { Table, Form, Button } from "react-bootstrap";
import Switch from 'react-bootstrap/Switch';
import { ServicesService } from "services/services-service";
import editIcon from "assets/pencil.svg";
import "styles/services-table.scss";
import Constants from "Constants";
import { useNavigate } from "react-router-dom";

export type ServicesTableProps = {
  services: any[];
  isAdmin: boolean;
}

const ServicesTable = (props: ServicesTableProps) => {

  const navigate = useNavigate();

  const getServiceStatus = (isActive: boolean): string => {
    if (!!isActive) {
      return Constants.Service.Status.active;
    }

    return Constants.Service.Status.inactive;
  }

  const editService = (id: number) => {
    navigate(`/services/${id}`);
  }

  return (
    <Table className="services-table">
      <thead>
        <tr>
          <th>Teenuse nimetus</th>
          <th>Peakasutaja</th>
          <th>Aktiivsus</th>
          {
            props.isAdmin && <th>Vali</th>
          }
          <th></th>
        </tr>
      </thead>
      <tbody className="services-table-body">
        {
          !!props.services &&
          props.services.map(x => (
            <tr key={x.id}>
              <td>{x.name}</td>
              <td>{x.adminUser}</td>
              <td>
                {
                  props.isAdmin ?
                    <Switch
                      type="switch"
                      inline
                      aria-label="Active"
                      defaultChecked={x.isActive}
                      onChange={async (e) => {
                        const succeed: boolean = await ServicesService.setServiceStatus(x.id, getServiceStatus(!x.isActive));
                        if (succeed)
                          x.isActive = !x.isActive;
                        else
                          e.target.checked = !e.target.checked;
                      }}
                    /> :
                    x.isActive ? "Aktiivne" : "Mitte aktiivne"
                }
              </td>
              {
                props.isAdmin &&
                <td>
                  <Form.Check
                    type="checkbox"
                    inline
                    onChange={(event: any) => {
                      x.checked = event.target.checked;
                    }}
                  />
                </td>
              }
              <td className="icon-cell">
                <Button variant="link" className="btn-icon" onClick={() => editService(x.id)}>
                    <img src={editIcon} />
                </Button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
}

export default ServicesTable;