import { $t } from "hooks/i18n";
import { Col, Row, Button, Modal } from "react-bootstrap";
import "../../styles/modal.scss";


type ConfirmModalProps = {
	show: boolean,
	title: string,
	message: string
	onCancel: () => void,
	onOk: () => void
}

const ConfirmModal = (props: ConfirmModalProps) => {
	return <Modal show={props.show} onHide={props.onCancel} className="ra-modal" size="lg">
		<Modal.Header closeButton>
			<Modal.Title>{props.title}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Row className="mb-3">
				<Col xs={12}>
					<div>{props.message}</div>
				</Col>
			</Row>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={props.onCancel}>
				{$t("Cancel")}
			</Button>
			<Button variant="primary" onClick={props.onOk}>
				{$t("Confirm")}
			</Button>
		</Modal.Footer>
	</Modal>;
}

export default ConfirmModal;
