import React from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { $t } from "hooks/i18n";
import PageTitle from "components/page/page-title";
import FormSection from "components/page/form-section";
import { useParams, useNavigate } from "react-router-dom";
import { hasValidationErrors, hideValidationErrors, showError } from "../../hooks/helpers";
import { EmailTemplateService } from "services/email-template-service";
import { IEmailTemplate } from "./email-template";
import { langSort } from "../../utils/lang-utils";

const EmailTemplateEditPage = () => {
  const [formData, setFormData] = React.useState<IEmailTemplate[] | null>(null);
  const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({});
  const [title, setTitle] = React.useState<string>("");

  let { id} = useParams();
  const navigate = useNavigate();

    const loadData = async () => {
        if (!id) {
            showError($t("Missing id parameter"));
            return;
        }
        await EmailTemplateService.getAllEmailTemplateByType(id)
            .then((resp) => {
                let items = (resp as IEmailTemplate[]).sort(langSort);
                setFormData(items);
            })
            .catch(ex => showError(ex));
        await EmailTemplateService.getEmailTemplateTypeById(id)
            .then((resp) => {
                setTitle(resp.description)
            })
            .catch(ex => showError(ex));
    }
  const setField = (val: any, field: string, index: number) => {
    let tmp = [...formData!];
    let element = tmp[index];
    const newElement = {...element, [field]: val}
    tmp[index] = newElement;
    setFormData(tmp as IEmailTemplate[]);
  }

  const showValidationErrors = (id: number, resp: any, setLocalErrors: Function, tmp: { [key: number]: {[property: string]: any} }) => {
    if (resp.errors === undefined)
        return false;

    for (let prop in resp.errors) {
        if (!Object.prototype.hasOwnProperty.call(resp.errors, prop)) continue;
        
        if (resp.errors[prop].length == 1){
             tmp[id] = {...tmp[id], [prop]: resp.errors[prop][0]}         
        }
        else if (resp.errors[prop].length > 1){
          tmp[id] = {...tmp[id], [prop]: resp.errors[prop]}
        }
    }

    return true;
  }
  
  const saveClick = async () => {
    if (!id || !formData) {
      return;
    }
    hideValidationErrors(localErrors, setLocalErrors);
    let errorObj = {} as { [key: number]: {[property: string]: any} };
    for (const value of formData) {
      await EmailTemplateService.saveEmailTemplate(value.id, value)
      .then((resp) => {
          showValidationErrors(value.id,resp, setLocalErrors, errorObj)
      })
      .catch(ex => showError(ex));
    }; 
    setLocalErrors(errorObj);
    if(!hasValidationErrors(errorObj)){
      closeForm();
    }
   
  }

  const closeForm = () => {
    navigate("/emailtemplates");
  }

  React.useEffect(() => {
    loadData();
  }, []);

  if (formData == null) return null;
  return (<div>
    <PageTitle title={title}>
      <div className="wp-nowrap" >
        <Button variant="secondary" onClick={() => closeForm()}>{$t("Cancel")}</Button>
        <Button variant="primary" onClick={() => saveClick()}>{$t("Save")}</Button>
      </div>
    </PageTitle>
    <div>
      <Container>
        <Form>
            {formData.map((x, i) =>
              <div key={x.id}>
              <FormSection title={x.languageName} required={false}>
               <Row>
               <Col xs={12} sm={6} md={4}>
                 <Form.Group className="mb-3" controlId="sender">
                   <Form.Label>{$t("Sender")}</Form.Label>
                   <Form.Control value={x.sender} onChange={e => setField(e.target.value, "sender", i)} isInvalid={!!localErrors[x.id]?.sender} />
                   {!!localErrors[x.id]?.sender ? <Form.Control.Feedback type="invalid">{localErrors[x.id]?.sender}</Form.Control.Feedback> : null}
                 </Form.Group>
               </Col>
               <Col xs={12} sm={6} md={4}>
                 <Form.Group className="mb-3" controlId="cc">
                   <Form.Label>{$t("Copy")}</Form.Label>
                   <Form.Control value={x.cc} onChange={e => setField(e.target.value, "cc", i)} isInvalid={!!localErrors[x.id]?.cc} />
                   {!!localErrors[x.id]?.cc ? <Form.Control.Feedback type="invalid">{localErrors[x.id]?.cc}</Form.Control.Feedback> : null}
                 </Form.Group>
               </Col>
               <Col xs={12} sm={6} md={4}>
                 <Form.Group className="mb-3" controlId="bCc">
                   <Form.Label>{$t("Blind Copy")}</Form.Label>
                   <Form.Control value={x.bCc} onChange={e => setField(e.target.value, "bCc", i)} isInvalid={!!localErrors[x.id]?.bCc} />
                   {!!localErrors[x.id]?.bCc ? <Form.Control.Feedback type="invalid">{localErrors[x.id]?.bCc}</Form.Control.Feedback> : null}
                 </Form.Group>
               </Col>
             </Row>
             <Row>
               <Col xs={12}>
                 <Form.Group className="mb-3" controlId="subject">
                   <Form.Label>{$t("Subject")}</Form.Label>
                   <Form.Control value={x.subject} onChange={e => setField(e.target.value, "subject", i)} isInvalid={!!localErrors[x.id]?.subject} />
                   {!!localErrors[x.id]?.subject ? <Form.Control.Feedback type="invalid">{localErrors[x.id]?.subject}</Form.Control.Feedback> : null}
                 </Form.Group>
               </Col>
             </Row>
             <Row>
             <Col xs={24} sm={24} md={24}>
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>{$t("Content")}</Form.Label>
                  <Form.Control as="textarea" rows={10} value={x.content} onChange={e => setField(e.target.value, "content", i)} isInvalid={!!localErrors[x.id]?.content} />
                  {!!localErrors[x.id]?.content ? <Form.Control.Feedback type="invalid">{localErrors[x.id]?.content}</Form.Control.Feedback> : null}
                </Form.Group>
              </Col>
             </Row>
               <Row>
                <Col xs={{ span: 12}} sm={{ span: 6, offset: 6 }} md={{ span: 4, offset: 8 }}>
                  <Form.Group className="mb-3" controlId="sender">
                    <Form.Label>{$t("Button Text")}</Form.Label>
                    <Form.Control value={x.buttonText} onChange={e => setField(e.target.value, "buttonText", i)} isInvalid={!!localErrors.buttonText} />
                    {!!localErrors.buttonText ? <Form.Control.Feedback type="invalid">{localErrors.buttonText}</Form.Control.Feedback> : null}
                  </Form.Group>
                </Col>
               </Row>
               <Row>
               <Col xs={{ span: 12}} sm={{ span: 6, offset: 6 }} md={{ span: 4, offset: 8 }}>
                 <Form.Group className="mb-3" controlId="buttonLink">
                   <Form.Label>{$t("Button Link")}</Form.Label>
                   <Form.Control value={x.buttonLink} onChange={e => setField(e.target.value, "buttonLink", i)} isInvalid={!!localErrors.buttonLink} />
                   {!!localErrors.buttonLink ? <Form.Control.Feedback type="invalid">{localErrors.buttonLink}</Form.Control.Feedback> : null}
                 </Form.Group>
               </Col>
               </Row>
             </FormSection>
             </div>
              ) 
              }
        </Form>
      </Container>
    </div>
  </div>);
}

export default EmailTemplateEditPage;
