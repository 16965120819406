import React from "react";
import { Button } from "react-bootstrap";
import CollapseMinus from "../icons/collapse-minus";
import CollapsePlus from "../icons/collapse-plus";

type FormSectionProps = {
    title: string,
    required: boolean | null,
    children: React.ReactNode | null
}

const FormSection = (props: FormSectionProps) => {
    const [open, setOpen] = React.useState(true);

    return (<div className="form-section">
        <div className="title">
            <Button variant="collapse" onClick={() => setOpen(!open)}>{open ? <CollapseMinus /> : <CollapsePlus />}</Button>
            <span>{props.title}</span>
            {props.required ? <span className="required"> *</span> : null}</div>
        <div style={{display: open ? "block" : "none"}}>{!props.children ? null : props.children}</div>
    </div>);
}

export default FormSection;
