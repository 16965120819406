import React from "react";
import { Button, Col, OverlayTrigger, Row, Form, Popover } from "react-bootstrap";
import { $t } from "hooks/i18n";
import { ApplicationData } from "types/edit-service-request";
import Constants, { LoggingOutFrom, ServiceAuthTypes } from "Constants";
import { ClassifierDto } from "dto/classifier-dto";
import BackendConfig from "../../../backend-config";
import { ServiceService } from "../../../services/service-service";


type ApplicationRowProps = {
  app: ApplicationData,
  localErrors: { [key: string]: any },
  index: number,
  regEnabled: boolean,
  allClassifiers: ClassifierDto[],
  onChange: (ev: ApplicationData) => void,
  onDelete: () => void
}

const ApplicationRow = (props: ApplicationRowProps) => {

    const setField = (val: any, field: string) => {
        let tmp = { ...props.app } as { [key: string]: any };
        setFieldNested(tmp, field, val);
        props.onChange(tmp as ApplicationData);
    }
    const deleteAppConfirmed = () => {
        props.onDelete();
    }
    const getError = (key: string) => {
        const propKey = `applications[${props.index}].${key}`
        const error = props.localErrors[propKey];
        return error;
    }
    const hasError = (key: string) => {
        if (!props.localErrors)
            return false;
        const propKey = `applications[${props.index}].${key}`
        const errors = props.localErrors[propKey];
        return errors?.length > 0;
    }
    const setFieldNested = (obj: any, path: any, value: any) => {
        var schema = obj;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {}
            schema = schema[elem];
        }

        schema[pList[len - 1]] = value;
    }

    const renderUnregisterFields = () => {
        return <React.Fragment>
            {props.regEnabled ? <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3">
                    <Form.Label>Teenusest loobumise API URL (POST)</Form.Label>
                    <Form.Control value={props.app.unregister_url} onChange={e => setField(e.target.value, "unregister_url")} isInvalid={!!props.localErrors.unregister_url} />
                    {!!props.localErrors.unregister_url ? <Form.Control.Feedback type="invalid">{props.localErrors.unregister_url}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col> : null}
            {props.regEnabled ? <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3">
                    <Form.Label>Teenusest on loobutud API URL (POST)</Form.Label>
                    <Form.Control value={props.app.unregistered_url} onChange={e => setField(e.target.value, "unregistered_url")} isInvalid={!!props.localErrors.unregistered_url} />
                    {!!props.localErrors.unregistered_url ? <Form.Control.Feedback type="invalid">{props.localErrors.unregistered_url}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col> : null}
            {props.regEnabled ? <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3">
                    <Form.Label>Teenusest loobumise API Auth Header</Form.Label>
                    <Form.Control value={props.app.unregister_auth_header} onChange={e => setField(e.target.value, "unregister_auth_header")} isInvalid={!!props.localErrors.unregister_auth_header} />
                    {!!props.localErrors.unregister_auth_header ? <Form.Control.Feedback type="invalid">{props.localErrors.unregister_auth_header}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col> : null}
        </React.Fragment>
    }

  return <Row>
    <br />
        <Col xs={6}>
        <span className="sub-title pad-left">Rakendus {props.index + 1}</span> 
        <br/>
      {$t("IsMobile")}
      <br /> <Form.Check
        checked={props.app.is_mobile_app}
        type="switch"
        inline
        onChange={e => setField(e.target.checked, "is_mobile_app")}
      />
     
        </Col>
        <Col xs={6} className="sub-title-actions">
            <OverlayTrigger trigger="click" placement="bottom" rootClose={true} overlay={
                <Popover id="del-confirm" className="theme-rara">
                    <Popover.Body>
                        <Row className="del-confirm-popup">
                            <Col xs={12} className="message">Kas oled kindel et soovid rakendust kustutada?</Col>
                            <Col xs={12} className="buttons">
                                <Button variant="secondary" onClick={() => document.body.click()}>Loobu</Button>
                                <Button variant="primary" onClick={() => deleteAppConfirmed()}>Kustuta</Button>
                            </Col>
                        </Row>
                    </Popover.Body>
                </Popover>
            }>
                <Button variant="secondary">Kustuta rakendus</Button>
            </OverlayTrigger>
        </Col>
        <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
                <Form.Label>{$t("Authentication type")}</Form.Label>
                <Form.Select value={props.app.auth_type} onChange={e => setField(e.target.value, "auth_type")} isInvalid={!!props.localErrors.service_auth_type}>
                    {ServiceAuthTypes.map(x => <option key={x.code} value={x.code}>{x.text}</option>)}
                </Form.Select>
                {!!props.localErrors.service_auth_type ? <Form.Control.Feedback type="invalid">{props.localErrors.service_auth_type}</Form.Control.Feedback> : null}
            </Form.Group>
        </Col>
        <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
                <Form.Label>{$t("Application ID")}</Form.Label>
                <Form.Control value={props.app.app_id} onChange={e => setField(e.target.value, "app_id")} isInvalid={!!props.localErrors.app_id} />
                {!!props.localErrors.app_id ? <Form.Control.Feedback type="invalid">{props.localErrors.app_id}</Form.Control.Feedback> : null}
            </Form.Group>
        </Col>
        {props.regEnabled ? <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-3">
                <Form.Label>{$t("Service SIERRA code")}</Form.Label>
                <Form.Control value={props.app.sierra_code} onChange={e => setField(e.target.value, "sierra_code")} isInvalid={!!props.localErrors.sierra_code} />
                {!!props.localErrors.sierra_code ? <Form.Control.Feedback type="invalid">{props.localErrors.sierra_code}</Form.Control.Feedback> : null}
            </Form.Group>
        </Col> : null}
        {
            props.app.auth_type === "OIDC" &&
            <>
                <Col xs={12} sm={6} md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>{$t("Return URL")}</Form.Label>
                        <Form.Control value={props.app.return_url} onChange={e => setField(e.target.value, "return_url")} isInvalid={!!props.localErrors.return_url} />
                        {!!props.localErrors.return_url ? <Form.Control.Feedback type="invalid">{props.localErrors.return_url}</Form.Control.Feedback> : null}
                    </Form.Group>
                </Col>
                {renderUnregisterFields()}
                <Col xs={12} sm={6} md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>{$t("Logging out")}</Form.Label>
                        <Form.Select value={props.app.logging_out} onChange={e => setField(e.target.value, "logging_out")} isInvalid={!!props.localErrors.logging_out}>
                            {!props.app.logging_out ? <option value=""></option> : null}
                            {LoggingOutFrom.map(x => <option key={x.code} value={x.code}>{x.text}</option>)}
                        </Form.Select>
                        {!!props.localErrors.logging_out ? <Form.Control.Feedback type="invalid">{props.localErrors.logging_out}</Form.Control.Feedback> : null}
                    </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>{$t("Logout URL")}</Form.Label>
                        <Form.Control value={props.app.logging_url} onChange={e => setField(e.target.value, "logging_url")} isInvalid={!!props.localErrors.logging_url} />
                        {!!props.localErrors.logging_url ? <Form.Control.Feedback type="invalid">{props.localErrors.logging_url}</Form.Control.Feedback> : null}
                    </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>{$t("Auth header secret")}</Form.Label>
                        <Form.Control value={props.app.auth_header_secret} onChange={e => setField(e.target.value, "auth_header_secret")} isInvalid={!!props.localErrors.auth_header_secret} />
                        {!!props.localErrors.auth_header_secret ? <Form.Control.Feedback type="invalid">{props.localErrors.auth_header_secret}</Form.Control.Feedback> : null}
                    </Form.Group>
                </Col>

            </>
        }

        {props.app.auth_type === "SAML" && <>

            <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3" controlId="type">
                    <Form.Label>{$t("Saml EntityId")}</Form.Label>
                    <Form.Control placeholder="https://rakendus.ee" value={props.app.application_saml_config_data?.entity_id} onChange={e => setField(e.target.value, "application_saml_config_data.entity_id")} isInvalid={!!!props.app.application_saml_config_data?.entity_id} />
                    {hasError('application_saml_config_data.entity_id') ? <Form.Control.Feedback type="invalid">{getError('application_saml_config_data.entity_id')}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col>

            <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label>{$t("Saml Metadata location")}</Form.Label>
                    <Form.Control placeholder="/saml/metadata" value={props.app.application_saml_config_data?.metadata_location} onChange={e => setField(e.target.value, "application_saml_config_data.metadata_location")} isInvalid={!!!props.app.application_saml_config_data?.metadata_location} />
                    {hasError('application_saml_config_data.metadata_location') ? <Form.Control.Feedback type="invalid">{getError('application_saml_config_data.metadata_location')}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col>


            <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3" controlId="type">
                    <Form.Label>{$t("Saml AcsLocation")}</Form.Label>
                    <Form.Control placeholder="/saml/acs" value={props.app.application_saml_config_data?.acs_location} onChange={e => setField(e.target.value, "application_saml_config_data.acs_location")} isInvalid={!!!props.app.application_saml_config_data?.acs_location} />
                    {hasError('application_saml_config_data.acs_location') ? <Form.Control.Feedback type="invalid">{getError('application_saml_config_data.acs_location')}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col>

            <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3" controlId="type">
                    <Form.Label>{$t("Saml LogoutLocation")}</Form.Label>
                    <Form.Control placeholder="/saml/logout" value={props.app.application_saml_config_data?.logout_location} onChange={e => setField(e.target.value, "application_saml_config_data.logout_location")} isInvalid={!!!props.app.application_saml_config_data?.logout_location} />
                    {hasError('application_saml_config_data.logout_location') ? <Form.Control.Feedback type="invalid">{getError('application_saml_config_data.logout_location')}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col>

            <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3" controlId="type">
                    <Form.Label>{$t("Binding type")}</Form.Label>
                    <Form.Select value={props.app.application_saml_config_data?.binding} onChange={e => setField(e.target.value, "application_saml_config_data.binding")} isInvalid={!!!props.app.application_saml_config_data?.binding}>
                        <option key="-" value="-"> - </option>
                        {props.allClassifiers.filter(x => x.type == Constants.ClassifierTypes.SamlBinding)
                            .map(x => <option key={x.value} value={x.value}>{x.value}</option>)}
                    </Form.Select>
                    {hasError('application_saml_config_data.binding') ? <Form.Control.Feedback type="invalid">{getError('application_saml_config_data.binding')}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col>

            <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3" controlId="type">
                    <Form.Label>{$t("NameId Format")}</Form.Label>
                    <Form.Select value={props.app.application_saml_config_data?.nameid_format} onChange={e => setField(e.target.value, "application_saml_config_data.nameid_format")} isInvalid={!!!props.app.application_saml_config_data?.nameid_format}>
                        <option key="-" value="-"> - </option>
                        {props.allClassifiers.filter(x => x.type == Constants.ClassifierTypes.SamlNameIdFormat)
                            .map(x => <option key={x.value} value={x.value}>{x.value}</option>)}
                    </Form.Select>
                    {hasError('application_saml_config_data.nameid_format') ? <Form.Control.Feedback type="invalid">{getError('application_saml_config_data.nameid_format')}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col>

            <Col xs={12} sm={2} md={2}>
                <Form.Group className="mb-3" controlId="type">
                    <Form.Label>{$t("Saml AuthnRequestSigned")}</Form.Label>
                    <Form.Check
                        checked={props.app.application_saml_config_data?.authn_request_signed}
                        type="switch"
                        inline
                        onChange={e => setField(e.target.checked, "props.app.application_saml_config_data?.authn_request_signed")}
                    />
                </Form.Group>
            </Col>
            <Col xs={12} sm={2} md={2}>
                <Form.Group className="mb-3" controlId="type">
                    <Form.Label>{$t("Saml WantAssertionsSigned")}</Form.Label>
                    <Form.Check
                        checked={props.app.application_saml_config_data?.want_assertions_signed}
                        type="switch"
                        inline
                        onChange={e => setField(e.target.checked, "props.app.application_saml_config_data?.want_assertions_signed")}
                    />
                </Form.Group>
            </Col>
            {renderUnregisterFields()}
            <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3">
                    <Form.Label>{$t("Logging out")}</Form.Label>
                    <Form.Select value={props.app.logging_out} onChange={e => setField(e.target.value, "logging_out")} isInvalid={!!props.localErrors.logging_out}>
                        {!props.app.logging_out ? <option value=""></option> : null}
                        {LoggingOutFrom.map(x => <option key={x.code} value={x.code}>{x.text}</option>)}
                    </Form.Select>
                    {!!props.localErrors.logging_out ? <Form.Control.Feedback type="invalid">{props.localErrors.logging_out}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={4}>
                <Form.Group className="mb-3">
                    <Form.Label>{$t("Logout URL")}</Form.Label>
                    <Form.Control value={props.app.logging_url} onChange={e => setField(e.target.value, "logging_url")} isInvalid={!!props.localErrors.logging_url} />
                    {!!props.localErrors.logging_url ? <Form.Control.Feedback type="invalid">{props.localErrors.logging_url}</Form.Control.Feedback> : null}
                </Form.Group>
            </Col>
            <Col xs={24} sm={24} md={24}>
                <Form.Group className="mb-3" controlId="type">
                    <Form.Label>{$t("Saml Cert")}</Form.Label><br />
                    <a href={ServiceService.getSamlPublicCertUrl()} target="_blank">certificate.crt</a>
                </Form.Group>
            </Col>

        </>
        }
        {props.app.auth_type === "CASv1" ?
            <>
                <Col xs={12} sm={6} md={4}>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>{$t("Servicename parameter name/value")}</Form.Label>
                        <Form.Control value={props.app.servicename_param} onChange={e => setField(e.target.value, "servicename_param")} isInvalid={!!props.localErrors.servicename_param} />
                        {!!props.localErrors.servicename_param ? <Form.Control.Feedback type="invalid">{props.localErrors.servicename_param}</Form.Control.Feedback> : null}
                    </Form.Group>
                </Col>
                {renderUnregisterFields()}
                <Col xs={12} sm={6} md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>{$t("Logging out")}</Form.Label>
                        <Form.Select value={props.app.logging_out} onChange={e => setField(e.target.value, "logging_out")} isInvalid={!!props.localErrors.logging_out}>
                            {!props.app.logging_out ? <option value=""></option> : null}
                            {LoggingOutFrom.map(x => <option key={x.code} value={x.code}>{x.text}</option>)}
                        </Form.Select>
                        {!!props.localErrors.logging_out ? <Form.Control.Feedback type="invalid">{props.localErrors.logging_out}</Form.Control.Feedback> : null}
                    </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>{$t("Logout URL")}</Form.Label>
                        <Form.Control value={props.app.logging_url} onChange={e => setField(e.target.value, "logging_url")} isInvalid={!!props.localErrors.logging_url} />
                        {!!props.localErrors.logging_url ? <Form.Control.Feedback type="invalid">{props.localErrors.logging_url}</Form.Control.Feedback> : null}
                    </Form.Group>
                </Col>
            </>
            : null
        }

    </Row>;
}

export default ApplicationRow;
