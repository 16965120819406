import BackendApi from "backend-api"
import BackendConfig from "backend-config"
import { AddServiceRequest } from "types/add-service-request";

export const ServicesService = {
    getAllServices: async function (): Promise<any | null> {
        let url = BackendConfig.Url() + "/Service/GetAllServices";
        return await BackendApi.get(url);
    },
    deleteServices: async function (serviceIds: number[]): Promise<boolean> {
        let url = BackendConfig.Url() + "/Service/DeleteServices";

        const result: boolean = await BackendApi.delete(url, serviceIds);

        return !!result;
    },
    /*deleteApplication: async function (applicationId: number): Promise<boolean> {
        let url = BackendConfig.Url() + `/Service/Application/${applicationId}`;

        const result: boolean = await BackendApi.delete(url);

        return !!result;
    },*/
    setServiceStatus: async function (serviceId: number, status: string): Promise<boolean> {
        let url = BackendConfig.Url() + `/Service/${serviceId}/Status`;

        const result: boolean = await BackendApi.put(url, status);

        return !!result;
    },
    addService: async function (serviceRequest: AddServiceRequest) {
        return new Promise((resolve, reject) => {
            const url = BackendConfig.Url() + "/Service"
            BackendApi.post(url, serviceRequest)
                .then(resp => resolve(resp))
                .catch(ex => reject(ex));
        });
    }
}
