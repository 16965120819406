import * as React from "react";
import { createContext, ReactNode, useState } from "react";
import Constants from "Constants";

interface UserContextProps {
  isLoggedIn: boolean;
  setUser(user: any): void;
  logout(): void;
  user?: any;
  getRole(): string;
}

export const UserContext = createContext<UserContextProps>({} as UserContextProps);

interface UserContextProviderProps {
  children: ReactNode;
}
export const UserContextProvider = ({ children, }: UserContextProviderProps) => {

  const [currentUser, setCurrentUser] = useState<any>({});

  const isLoggedIn = (): boolean => {
    return true;
  };
  const logoutUser = (): void => {
  };
  const user = () => {

    return currentUser;
  };
  const setUser = (user: any) => {

    return setCurrentUser(user);
  };
  const getUserRole = (): string => {
    const query = new URLSearchParams(location.search);
    return query.get("role") ?? Constants.Roles.anonymous;
  }

  const value = {
    isLoggedIn: isLoggedIn(),
    setUser,
    logout: logoutUser,
    user,
    getRole: getUserRole,
  };
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}