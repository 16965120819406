import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Pagination,
} from "react-bootstrap";
import { UserRights } from "Constants";
import "styles/page-template.scss";
import { showError } from "../../hooks/helpers";
import { UserData } from "../../types/user-data";
import { ActivityLogService } from "services/activitylog-service";
import { ActivityLogResultSet } from "./activitylog-data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

type ServicesPageProps = {
  user: UserData;
};

const createPages = (res: ActivityLogResultSet, setPages: Function) => {
  let pages = [];
  for (var i = 1; i <= Math.ceil(res.totalItems / res.pageSize); i++) {
    pages.push(i);
  }
  setPages(pages);
};

const ActivityLogPage = (props: ServicesPageProps) => {
  const [activityLogs, setActivityLogs] = useState<ActivityLogResultSet | null>(
    null
  );
  const [pages, setPages] = useState<number[]>([1]);
  const [page, setPage] = useState<number>(1);
  const pageSize = 25;
  const [validFrom, setValidFrom] = useState<Date>();
  const [validTo, setValidTo] = useState<Date>();

  const getPageTitle = (): string => {
    switch (props.user.rights) {
      case UserRights.ADMIN:
        return "Logid";
      case UserRights.MAIN_USER:
        return "Minu Logid";
      default:
        return "Logid";
    }
  };

  const loadData = (
    loadPage: number,
    fromDate: Date | undefined,
    toDate: Date | undefined
  ) => {
    setPage(loadPage);
    ActivityLogService.fetchActivityLogs(
      loadPage,
      pageSize,
      formatDataParam(fromDate),
      formatDataParam(toDate)
    )
      .then((resp: any) => {
        setActivityLogs(resp as ActivityLogResultSet);
        createPages(resp as ActivityLogResultSet, setPages);
      })
      .catch((ex) => showError(ex));
  };

  const initialLoad = (): void => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setValidFrom(firstDay);
    setValidTo(lastDay);
    loadData(1, firstDay, lastDay);
  };

  const pageClick = (newPage: number) => {
    if (newPage < 1) newPage = 1;
    if (newPage > pages.length) newPage = pages.length;
    loadData(newPage, validFrom, validTo);
  };

  const handleValidFrom = (date: Date) => {
    setValidFrom(date);
  };
  const handleValidTo = (date: Date) => {
    setValidTo(date);
  };

  const formatDataParam = (date: Date | undefined): string => {
    return format(date!, "yyyy-MM-dd");
  };

  const handleDownload = async (): Promise<void> => {
    await ActivityLogService.downloadActivityLogCsv(
      formatDataParam(validFrom),
      formatDataParam(validTo)
    );
  };

  React.useEffect(() => {
    initialLoad();
  }, []);

  if (activityLogs == null) return null;

  return (
    <Container fluid className="title-header">
      <Container>
        <Row className="title-header pt-3 mb-5">
          <Col xs={4} className="title-row d-flex align-items-center">
            <h1>{getPageTitle()}</h1>
          </Col>
          <Col xs={5} className="title-row d-flex align-items-center">
            <DatePicker
              selected={validFrom}
              shouldCloseOnSelect={false}
              showTimeInput={false}
              timeInputLabel="aeg"
              timeFormat="HH:mm"
              dateFormat="dd.MM.yyyy"
              onChange={handleValidFrom}
            />
            <DatePicker
              selected={validTo}
              shouldCloseOnSelect={false}
              showTimeInput={false}
              timeInputLabel="aeg"
              timeFormat="HH:mm"
              dateFormat="dd.MM.yyyy"
              onChange={handleValidTo}
            />
          </Col>
          <Col
            xs={3}
            className="d-flex align-items-center justify-content-end gap-5"
          >
            <Button
              variant="secondary"
              onClick={() => loadData(1, validFrom, validTo)}
            >
              Otsi
            </Button>
            <Button onClick={() => handleDownload()}>Lae alla</Button>
          </Col>
        </Row>
        <Table className="table">
          <thead>
            <tr className="table-header">
              <th style={{width: "17%"}}>Kellaeg ja kuupäev</th>
              <th style={{width: "13%"}}>Teenus</th>
              <th style={{width: "10%"}}>Sündmus</th>
              <th style={{width: "50%"}}>Päringu sisu</th>
              <th style={{width: "10%"}}>Kasutaja nimi</th>
            </tr>
          </thead>
          <tbody>
            {!!activityLogs.items &&
              activityLogs.items.map((el, i) => (
                <tr key={i}>
                  <td>
                    {format(new Date(el.createAt), "HH:mm:ss dd.MM.yyyy")}
                  </td>
                  <td>{el.serviceName}</td>
                  <td>{el.activity}</td>
                  <td>{el.data}</td>
                  <td>{el.userName}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {pages.length <= 1 ? null : (
          <Pagination className="pagination pb-5">
            <Pagination.Prev
              className="prev"
              onClick={() => pageClick(page - 1)}
            />
            {pages.map((x, i) => (
              pages.length < 10 || x == 1 || (x > page-5 && x-5 < page ) || i == pages.length - 1 ?  
              <Pagination.Item
                key={i}
                active={page == x}
                onClick={() => pageClick(x)}
              >
                {x}
                </Pagination.Item> : <></>
            ))}
            <Pagination.Next
              className="next"
              onClick={() => pageClick(page + 1)}
            />
          </Pagination>
        )}
      </Container>
    </Container>
  );
};

ActivityLogPage.displayName = "Activity log";

export default ActivityLogPage;
