import { Container, Row, Col, Button } from "react-bootstrap";
import * as React from "react";
import "styles/services-page.scss";
import { ServicesService } from "services/services-service";
import ServicesTable from "pages/services/components/services-table";
import Constants, { UserRights } from "Constants";
import PageHeader from "components/page-header";
import { useNavigate } from "react-router-dom";
import { UserData } from "../../types/user-data";

type ServicesPageProps = {
    user: UserData
}

const ServicesPage = (props: ServicesPageProps) => {
  const [services, setServices] = React.useState<any[]>([]);

  const navigate = useNavigate();

  const getRole = (): string => {
    const query = new URLSearchParams(location.search);
    return query.get("role") ?? Constants.Roles.anonymous;
  }

  const getPageTitle = (): string => {
    switch (props.user.rights) {
      case UserRights.ADMIN:
        return "Teenused";
        case UserRights.MAIN_USER:
        return "Minu teenused";
      default:
        return "Teenused";
    }
  }

  const loadPage = async () => {
    loadServices();
  }

  const loadServices = async () => {
    const _services = await ServicesService.getAllServices();
    setServices(_services);
  }

  const addService = () => {
    navigate("/services/add");
  }

  const deleteSelectedServices = async () => {
    const selectedIds = services.filter(x => !!x.checked).map(x => x.id);
    if (selectedIds.length === 0)
      return;
    const succeed = await ServicesService.deleteServices(selectedIds);
    if (succeed)
      setServices(services.filter(x => !x.checked));
  }

  const headerElements = (
    <Col xs={8} className="title-right">
      <Button variant="primary" onClick={addService}>Lisa uus</Button>
      <Button variant="secondary" onClick={deleteSelectedServices}>Kustuta valitud</Button>
    </Col>
  );

  React.useEffect(() => {
    loadPage();
  }, []);

  return (
    <React.Fragment>
          <div className="services-page">
              <PageHeader title={getPageTitle()} children={UserRights.isAdmin(props.user.rights) ? headerElements : null} />
        <div className="services-content">
          <Container>
            <Row>
                          <Col>
                              <ServicesTable services={services} isAdmin={UserRights.isAdmin(props.user.rights)} />
                          </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ServicesPage;