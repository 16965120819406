import { Container, Row, Col, Button } from "react-bootstrap";
import * as React from "react";
import "styles/email-template-page.scss";
import Constants from "Constants";
import PageHeader from "components/page-header";
import { useNavigate } from "react-router-dom";
import { EmailTemplateService } from "services/email-template-service";
import EmailTemplateTable from "./components/email-template-table";
import { IEmailTemplateType } from "./email-template-type";

const EmailTemplatesPage = () => {
  const [emailTemplateTypes, setEmailTemplatesTypes] = React.useState<IEmailTemplateType[]>([]);

  const navigate = useNavigate();

  //Get roles from logged in user, not implemented yet // TO DO
  const getRole = (): string => {
    const query = new URLSearchParams(location.search);
    return query.get("role") ?? Constants.Roles.anonymous;
  }

  const getPageTitle = (role: string): string => {
    switch (role) {
      case Constants.Roles.admin:
        return "Kirjamallide haldus";
      case Constants.Roles.adminuser:
        return "Minu kirjamallide haldus";
      default:
        return "Kirjamallide haldus";
    }
  }

  const loadPage = async () => {
    loadEmailTemplates();
  }

  const loadEmailTemplates = async () => {
    const _emailTemplatesTypes = await EmailTemplateService.getAllEmailTemplateTypes();
    setEmailTemplatesTypes(_emailTemplatesTypes);
  }

  React.useEffect(() => {
    loadPage();
  }, []);

  return (
    <React.Fragment>
      <div className="email-template-page">
        <PageHeader title={getPageTitle(getRole())} children={null} />
        <div className="email-template-content">
          <Container>
            <Row>
              <Col>
                <EmailTemplateTable services={emailTemplateTypes} role={getRole()} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmailTemplatesPage;