//https://stackoverflow.com/a/201378
export const emailFormat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const validateEmail = (val: string) => {
  return val.match(emailFormat);
}

export const showValidationErrors = (resp: any, setLocalErrors: Function) => {
  if (resp.errors === undefined && resp.error === undefined)
    return false;

  if (resp.error) {    
    return true;
  }
  let tmp = {} as { [key: string]: any };
  let globalErrors = {} as { [key: string]: any };
  let hasGlobalError = false;
  for (let prop in resp.errors) {
    if (!Object.prototype.hasOwnProperty.call(resp.errors, prop)) continue;
    if (tmp[prop] === undefined) {
      globalErrors[prop] = resp.errors;
      hasGlobalError = true;
    }
    if (resp.errors[prop].length > 0) {
      tmp[prop] = resp.errors[prop][0];
    }
  }

  if (hasGlobalError) {
    showError(globalErrors);
  }

  setLocalErrors(tmp);

  return true;
}

export const showError = (ex: any) => {
    console.error(ex);
  }
