import { useState, useEffect } from 'react';

export const getIsMobile = (width: Number) => {
  return width < 992;
}

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(() => getIsMobile(window.innerWidth));

  useEffect(() => {
    const calculate = function () {
      setIsMobile(getIsMobile(window.innerWidth))
    };
    window.addEventListener('resize', calculate);
    return () => window.removeEventListener('resize', calculate);
  }, []);

  return isMobile;
}
export default useIsMobile;
