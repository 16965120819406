import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Pagination,
  Form,
} from "react-bootstrap";

import { UserRights } from "Constants";
import "styles/page-template.scss";
import { showError } from "../../hooks/helpers";
import { UserData } from "../../types/user-data";
import { ActivityLogService } from "services/activitylog-service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { StatisticsService } from "services/statistics-service";
import "styles/statistics-page.scss";
import Select, { MultiValue } from "react-select";
import { ServiceData } from "types/service-data";
import { ServicesService } from "services/services-service";
import { UserService } from "services/user-service";
import filterIcon from "assets/filter.svg";
import { Link } from "react-router-dom";
import EmailModal from "components/modals/email-modal";


type ServicesPageProps = {
  user: UserData;
};

type SelectOption = {
  value: number;
  label: string;
};

const StatisticsPage = (props: ServicesPageProps) => {
  const [StatisticsLogs, setStatisticsLogs] = useState<any>(
    null
  );

  const [allServices, setAllServices] = React.useState<SelectOption[] | null>(null);
  const [formServices, setFormServices] = React.useState<SelectOption[] | null>(null);
  const [report, setReport] = React.useState<number>(1);
  const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({});
  const [filterSelection, setFilterSelection] = React.useState<boolean>(false);
  const [validFrom, setValidFrom] = useState<Date>();
  const [validTo, setValidTo] = useState<Date>();
  const [granuality, setGranuality] = useState<number>(3);
  const [emailModal, setEmailModal] = React.useState<{ [key: string]: any }>({ open: false });

  const getPageTitle = (): string => {
    switch (props.user.rights) {
      case UserRights.ADMIN:
        return "Statistika";
      case UserRights.MAIN_USER:
        return "Minu Statistika";
      default:
        return "Statistika";
    }
  };

  const hideValidationErrors = (
    localErrors: any,
    setLocalErrors: Function
  ) => {
    let tmp = { ...localErrors };
    for (let i in tmp) {
      if (tmp.hasOwnProperty(i)) {
        tmp[i] = null;
      }
    }
    setLocalErrors(tmp);
  };

  const openEmailModal = () => {
    setEmailModal({ open: true });
  }
  const sendEmailConfirmed = (email : string | undefined) => {
    setEmailModal({ open: false });
    sendEmail(email);
  }

  const showValidationErrors = (setLocalErrors: Function) => {
    
    if(props.user.rights === UserRights.MAIN_USER && formServices?.length == 0){
      let tmp = {} as { [key: string]: any };
      tmp["services"] = "Vali vähemalt üks teenus!";
      setLocalErrors(tmp);
      return false
    }
    return true;
  };

  const loadData = (
    fromDate: Date | undefined,
    toDate: Date | undefined,
    type: number
  ) => {
    setReport(type);
    hideValidationErrors(localErrors, setLocalErrors);
    if(!showValidationErrors(setLocalErrors)){
      return;
    }

    let serviceIds =  formServices?.map(x => x.value);
    let gran = granuality;

    if(type === 1){
      StatisticsService.fetchAuthenticationStatisticLogs(
        formatDataParam(fromDate),
        formatDataParam(toDate),
        gran,
        serviceIds
      )
        .then((resp: any) => {
          setStatisticsLogs(resp as any);
        })
        .catch((ex) => showError(ex));
    }
    else{
    
      StatisticsService.fetchRegistrationStatisticLogs(
        formatDataParam(fromDate),
        formatDataParam(toDate),
        gran,
        serviceIds
      )
        .then((resp: any) => {
          setStatisticsLogs(resp as any);
        })
        .catch((ex) => showError(ex));
    }
  };

  const loadGranualityData = (
    fromDate: Date | undefined,
    toDate: Date | undefined,
    granuality: number
  ) => {
    setGranuality(granuality);
    hideValidationErrors(localErrors, setLocalErrors);
    if(!showValidationErrors(setLocalErrors)){
      return;
    }

    let serviceIds =  formServices?.map(x => x.value);
    let gran = granuality;

    if(report === 1){
      StatisticsService.fetchAuthenticationStatisticLogs(
        formatDataParam(fromDate),
        formatDataParam(toDate),
        gran,
        serviceIds
      )
        .then((resp: any) => {
          setStatisticsLogs(resp as any);
        })
        .catch((ex) => showError(ex));
    }
    else{
    
      StatisticsService.fetchRegistrationStatisticLogs(
        formatDataParam(fromDate),
        formatDataParam(toDate),
        gran,
        serviceIds
      )
        .then((resp: any) => {
          setStatisticsLogs(resp as any);
        })
        .catch((ex) => showError(ex));
    }
  };

  const initialLoad = (): void => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setValidFrom(firstDay);
    setValidTo(lastDay);
  };

  const handleValidFrom = (date: Date) => {
    setValidFrom(date);
  };
  const handleValidTo = (date: Date) => {
    setValidTo(date);
  };

  const formatDataParam = (date: Date | undefined): string => {
    return format(date!, "yyyy-MM-dd");
  };

  const handleDownload = async (type: number): Promise<void> => {
    hideValidationErrors(localErrors, setLocalErrors);
    if(!showValidationErrors(setLocalErrors)){
      return;
    }
    let gran = granuality;
    let serviceIds =  formServices?.map(x => x.value);
    await StatisticsService.downloadReportCsv(
      formatDataParam(validFrom),
      formatDataParam(validTo),
      gran,
      serviceIds,
      report
    );
  };
  const sendEmail = async (email: string| undefined): Promise<void> => {
    if(!email){
      return
    }
    hideValidationErrors(localErrors, setLocalErrors);
    if(report == null){
      return;
    }
    if(!showValidationErrors(setLocalErrors)){
      return;
    }
    let gran = granuality;
    let serviceIds =  formServices?.map(x => x.value);
    await StatisticsService.sendReportEmail(
      formatDataParam(validFrom),
      formatDataParam(validTo),
      gran,
      serviceIds,
      report,
      email
    );
  };

  const formatDataHeader = (date: Date | undefined): string => {
    return format(date!, "dd.MM.yyyy");
  };

  const toggleFilters = (): void => {
    setFilterSelection(!filterSelection);
  };

  const selectPeriod = (type: number): boolean => {
    let today = new Date();
    let firstDay;
    let lastDay;
    switch(type) {
      case 1:
        let yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        setValidFrom(yesterday);
        setValidTo(yesterday);
        break;
      case 2:
        firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setValidFrom(firstDay);
        setValidTo(lastDay);
        break;
      case 3:
        let currentYear = today.getFullYear();
        firstDay = new Date(currentYear, 0, 1);
        lastDay = new Date(currentYear, 11, 31);
        setValidFrom(firstDay);
        setValidTo(lastDay);
    }
    return false;
  };

  const loadServices = () => {
    ServicesService.getAllServices()
        .then((servResp: any) => {
            let allServ = servResp.map((x: ServiceData) => ({ value: x.id, label: x.name } as SelectOption));
            setAllServices(allServ);
            if(props.user.rights === UserRights.MAIN_USER){
              UserService.fetchUser(props.user.id)
                    .then((userResp: any) => {
                        setFormServices(allServ?.filter((x: { value: any; }) => userResp.services.indexOf(x.value) >= 0) ?? []);     
                    })
                    .catch(ex => showError(ex));
            }
        })
        .catch(ex => showError(ex));
}
const setFormService = (serv: MultiValue<SelectOption>) => {
  setFormServices([...serv]);
}

  React.useEffect(() => {
    loadServices();
    initialLoad();
    
  }, []);

  const renderTableHeader = () => {
    if (!!StatisticsLogs && StatisticsLogs.length === 0)
        return null;
    let firstLog = StatisticsLogs[0];
    return (
      <tr className="table-header">
        {firstLog && Object.entries(firstLog).map(([key, value], i) => {
          if(key !== 'StatType' && key !== 'Description' && key !== 'Kokku'){
              return <th key={i}>{formatDataHeader(new Date(key))}</th>
          }else{
              return <th key={i}></th>
          }
      })}
      </tr> ) ;
  } 

  const renderAdditionalRow = (el: any, i:any, array:any) => {
      if(i == 0){
          return null;
      }
      let previousItem = array[i - 1];
      if(el.StatType !== previousItem.StatType){
        return (
            <tr key={'b' + i}>
                {Object.entries<any>(el).map(([key, value], i) => {
                if(key == 'StatType'){          
                    return <th key={i}>{value}</th>          
                }          
                else{
                    return <th key={i}></th>
                }
                })}
            </tr>)
      }
  }

    const renderTableBody = () => {
        return (
            <tbody>
                {!!StatisticsLogs &&
                    StatisticsLogs.map((el: any, i: any, array: any) => (
                        <React.Fragment key={`a${i}`}>
                            {renderAdditionalRow(el, i, array)}
                            <tr>
                                {Object.entries<any>(el).map(([key, value], j) => {
                                    if (key !== 'StatType') {
                                        if (value == 'Audentimisi KOKKU' || value == 'Registreerunud kasutajaid KOKKU') {
                                            return <th key={`a${i}-${j}`}></th>
                                        } else {
                                            return <th key={`a${i}-${j}`} style={{ fontWeight: "normal" }}>{value == null ? 0 : value}</th>
                                        }
                                    } else {
                                        if (key == 'StatType' && value == 'Kokku') {
                                            if (report === 1) {
                                                return <th key={`a${i}-${j}`}>Autentimisi Kokku</th>
                                            } else {
                                                return <th key={`a${i}-${j}`}>Registreerimisi kokku</th>
                                            }
                                        } else {
                                            return <th key={`a${i}-${j}`}></th>
                                        }
                                    }
                                })}
                            </tr>
                        </React.Fragment>
                    ))}
            </tbody>
        )
    }

  return (
    <Container fluid className="title-header">
      <Container>
        <Row className="title-header pt-3 mb-5">
          <Col xs={4} className="title-row d-flex align-items-center">
            <h1>{getPageTitle()}</h1>
          </Col>
          <Col xs={5} className="title-row mt-3">
            <div className="d-flex align-items-center">
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label><div>alates<span className="required-red">*</span></div></Form.Label>
                <DatePicker
                  selected={validFrom}
                  shouldCloseOnSelect={false}
                  showTimeInput={false}
                  timeInputLabel="aeg"
                  timeFormat="HH:mm"
                  dateFormat="dd.MM.yyyy"
                  onChange={handleValidFrom}
                />
              </Form.Group> 
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label><div>kuni<span className="required-red">*</span></div></Form.Label>
                <DatePicker
                  selected={validTo}
                  shouldCloseOnSelect={false}
                  showTimeInput={false}
                  timeInputLabel="aeg"
                  timeFormat="HH:mm"
                  dateFormat="dd.MM.yyyy"
                  onChange={handleValidTo}
                />
            </Form.Group>
            </div>
            <div className="d-flex align-items-center gap-5"> 
              <button onClick={() => selectPeriod(1)} className="link link-dark border-0 " >
                 <u>Eilne päev</u>
              </button>
              <button onClick={() => selectPeriod(2)} className="link link-dark border-0 " >
                 <u>Käesolev kuu</u>
              </button>
              <button onClick={() => selectPeriod(3)} className="link link-dark border-0 " >
                 <u>Käesolev aasta</u>
              </button>
            </div>    
          </Col>
          <Col
            xs={3}
            className="d-flex align-items-center justify-content-end gap-5"
          >
            <Button
              variant="secondary"
              onClick={() => handleDownload(1)}
            >
              Lae alla
            </Button>
            <Button onClick={() => openEmailModal()}>Saada e-kirjaga</Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={1} className="title-row d-flex align-items-center">
          </Col>
          <Col
            xs={11}
            className="d-flex align-items-center justify-content-end gap-4"
          >
             <h6>{!!localErrors.services ? <div className="local-error">{localErrors.services}</div> : null}</h6>
            <h5 style={{fontWeight: "normal"}}>Vali teenus</h5>
            <Form.Group controlId="controlServices">
                                <Select
                                    className="selectMulti"
                                    value={formServices}
                                    onChange={(option) => setFormService(option)}
                                    options={allServices ?? []}
                                    isMulti
                                    classNamePrefix="select"
                                    noOptionsMessage={() => "Valikuvõimalusi pole"}
                                    placeholder="Vali teenused"
                                />
                            </Form.Group>
            <Button
              className="statistics-btn"
              onClick={() => loadData(validFrom, validTo,2)}
            >
              Registreerumise aruanne
            </Button >
            <Button className="statistics-btn" onClick={() => loadData(validFrom, validTo,1)}>Autentimise aruanne</Button>
            <Button
                                        variant="link"
                                        className="btn-icon"
                                        onClick={() => toggleFilters()}
                                    >
                                        <img src={filterIcon} />
                                    </Button>
          </Col>
        </Row>
        {filterSelection &&  <Row className="mb-3 me-4">
          <Col xs={1} className="title-row d-flex align-items-center">
          </Col>
          <Col
            xs={11}
            className="d-flex align-items-center justify-content-end gap-4"
          >
             <Button
              className="statistics-btn"
              onClick={() => loadGranualityData(validFrom, validTo,2)}
            >
              Kokku
            </Button >
            <Button
              className="statistics-btn"
              onClick={() => loadGranualityData(validFrom, validTo,3)}
            >
              Kuu
            </Button >
            <Button className="statistics-btn me-3" onClick={() => loadGranualityData(validFrom, validTo,1)}>Päev</Button>
            
          </Col>
          
        </Row>}
        {StatisticsLogs && <Table className="table table-sticky-header">
          <thead>
          {renderTableHeader()}
          </thead>        
            {renderTableBody()}   
        </Table>}
      </Container>
      {emailModal.open ? <EmailModal
                show={emailModal.open}
                title=""
                message="Palun sisesta emaili aadress"
                onCancel={() => setEmailModal({ open: false })}
                onOk={sendEmailConfirmed}
            /> : null}
    </Container>
  );
};

StatisticsPage.displayName = "Activity log";

export default StatisticsPage;
