import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import editIcon from "assets/pencil.svg";
import SingleTranslationEdit from "./single-translation-edit";
import { ITranslation } from "pages/translation/translation";
import { DefaultLanguage } from "Constants";

interface PropTypes {
  translations: ITranslation[],
  saveChanges : (data : ITranslation[]) => any
}

const TranslationEditor = (props: PropTypes) => {
  const {translations, saveChanges} = props;
  const [expanded, setExpanded] = useState<boolean>();
  const [changedTranslations, setChangedTranslations] = useState<ITranslation[]>([...translations]);
  useEffect(() => {
    setChangedTranslations([...translations]);
  }, [translations]);
  const rowStyle = expanded ? "translation-bloc-expanded" : "translation-bloc";

  const handleClose = () => {
    setChangedTranslations(translations.map((c: ITranslation) => {
      return {...c};
    }))
    setExpanded(false);
  }
  const handleSave = () => {
    saveChanges(changedTranslations);
    setExpanded(false);
  }
  const handleChange = (data: ITranslation) => {
    setChangedTranslations(changedTranslations.map((c: ITranslation) => {
      if(c.id === data.id){
        return {
          ...c,
          value: data.value
        };
      }
      return c;
    }))
  }

  const getDefaultTranslation = () => changedTranslations.find(x => x.language === DefaultLanguage)

  return getDefaultTranslation() ? (
    <>
      <tr className={rowStyle}>
        <td>{getDefaultTranslation()!.key}</td>
        <td>{getDefaultTranslation()!.value}</td>
        <td>
          <Button
            variant="link"
            className="btn-icon"
            onClick={() => setExpanded((prev) => !prev)}
          >
            <img src={editIcon} />
          </Button>
        </td>
      </tr>
      {expanded &&
        changedTranslations.map((translation,i) => (
          <SingleTranslationEdit
            key={i}
            handleClose={handleClose}
            translation={translation}
            handleSave={handleSave}
            handleChange={handleChange}
          />
        ))}
    </>
  ) : (<></>);
};

export default TranslationEditor;
