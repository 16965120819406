import { useState } from "react";
import { Button, Form } from "react-bootstrap"
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { SpecialRightsService } from "services/special-rights-service";
import { SpecialRightsApplicationFile } from "../special-rights-application-models";
import { EmbedContent, EmbedModal } from "components/modals/embed-modal";
import { DownloadModal } from "components/modals/download-modal";

interface SpecialRightsApplicationProps {
    id: number,
    fileLinks: SpecialRightsApplicationFile[],
    name: string,
    personCode: string,
    submitDate: string
}

const SpecialRightsApplicationEdit = (props: SpecialRightsApplicationProps) => {
    const [acceptExpanded, setAcceptExpanded] = useState<boolean>(false);
    const [rejectExpanded, setRejectExpanded] = useState<boolean>(false);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [showEmbedModal, setShowEmbedModal] = useState<boolean>(false);
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [downloadModalLink, setDownloadModalLink] = useState<string | null>(null);
    const [embedModalContent, setEmbedModalContent] = useState<EmbedContent[]>();

    const [specialRightsExpiration, setSpecialRightsExpiration] = useState<Date>();

    const [rejectReason, setRejectReason] = useState<string>("");
    
    const startAccept = () => {
        setRejectExpanded(false);
        setAcceptExpanded(true);
    }

    const startReject = () => {
        setRejectExpanded(true);
        setAcceptExpanded(false);
    }

    const rejectApplication = async () => {
        await SpecialRightsService.rejectApplication(props.id, rejectReason).then(_ => {
            setIsDeleted(true);
        });
    }

    const acceptApplication = async () => {
        await SpecialRightsService.acceptApplication(props.id, specialRightsExpiration?.toISOString()).then(_ => {
            setIsDeleted(true);
        });
    }

    // test with multiple files
    // test with big pdf
    const displayFile = async (id: number, name: string) => {
        try {
            const fileLink = SpecialRightsService.createFileLink(id, name);
            var response = await SpecialRightsService.getDataUrl(encodeURI(fileLink));
            console.log(response);

            if (response.length === 0) {
                const downloadUrl = SpecialRightsService.createDownloadFileLink(id, name);
                setShowDownloadModal(true);
                setDownloadModalLink(downloadUrl);
                return;
            }

            setEmbedModalContent(response)
            setShowEmbedModal(true);

        } catch(ex) {
            console.error(ex)
        }

        return;
    }

    if (isDeleted) {
        return (<></>);
    }

    return (
        <>
            <tr>
                <td className="d-flex flex-column">
                    {!!props.fileLinks && props.fileLinks.map((item, index)=> {
                        return (
                            <div key={index}>
                                <span>{item.name}</span>
                                <Button size="sm" variant="link" onClick={async() => displayFile(item.id, item.name)}>
                                    Ava
                                </Button>
                            </div>
                        )
                    })}
                </td>
                <td>{props.name}</td>
                <td>{props.personCode}</td>
                <td>{format(new Date(props.submitDate), "dd.MM.yyyy HH:mm")}</td>
                <td>
                    <Button variant="primary" className="mx-4" onClick={startAccept}>Kinnita</Button>
                    <Button variant="secondary" onClick={startReject}>Keeldu</Button>
                </td>
            </tr>
            { acceptExpanded && (
                <tr>
                    <td colSpan={2}>Kinnitan eriõiguse tähtajatult või kuupäevani:</td>
                    <td>
                        <DatePicker
                            selected={specialRightsExpiration}
                            shouldCloseOnSelect={false}
                            showTimeInput={false}
                            timeInputLabel="aeg"
                            timeFormat="HH:mm"
                            dateFormat="dd.MM.yyyy"
                            onChange={(date: Date) => setSpecialRightsExpiration(date)}
                        />
                    </td>
                    <td>
                        <Button variant="primary" onClick={acceptApplication}>Salvesta</Button>
                    </td>
                </tr>
            )
            }
            { rejectExpanded && (
                <tr>
                    <td colSpan={2}>Keeldun eriõigusest põhjusega:</td>
                    <td>
                        <Form.Control className="input" type="text" maxLength={1000} value={rejectReason} onChange={(e) => setRejectReason(e.target.value)}/>
                    </td>
                    <td>
                        <Button variant="primary" disabled={rejectReason.length === 0} onClick={rejectApplication}>Salvesta</Button>
                    </td>
                </tr>
            )
            }

            { embedModalContent && 
                <EmbedModal isOpen={showEmbedModal}
                    onClose={() => setShowEmbedModal(false)}
                    src={embedModalContent} 
                />
            }

            <DownloadModal 
                isOpen={showDownloadModal}
                onClose={() => setShowDownloadModal(false)}
                downloadLink={downloadModalLink}
            />
        </>
    )
}

export default SpecialRightsApplicationEdit;
