const BackendConfig = {
  Url: function (): string {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return "https://localhost:7046";
    } else {
      return "/backendproxy";// use /backendproxy with nginx
    }
  }
};
export default BackendConfig;

