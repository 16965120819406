import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { ITranslation } from "../translation";


interface PropTypes {
  translation: ITranslation,
  handleSave: (data:any) => void,
  handleClose: () => void,
  handleChange: (data : ITranslation) => void
}

const TranslateEditor = (props: PropTypes) => {
  const { handleClose, handleSave, translation, handleChange } = props;
  const [translationData, setTranslationData] = useState<ITranslation>(translation);

  const handleTranslationState = (e: any) => {
    setTranslationData((prev) => {
      const newState = { ...prev, value:e.target.value};
      handleChange(newState);
      return newState
    }
    );  
  }
   

  return (
    <>
      <tr>
        <td colSpan={3}>
          <span className="translation-edit-container">
            <span>{translationData.language}</span>
            <Form.Control
              className="input"
              type="text"
              value={translationData.value}
              onChange={handleTranslationState}
            />
            <Col className="d-flex align-items-center justify-content-end gap-5">
              <Button variant="secondary" onClick={handleClose}>
                Loobu
              </Button>
              <Button onClick={handleSave}>Salvesta</Button>
            </Col>
          </span>
        </td>
      </tr>
    </>
  );
};

export default TranslateEditor;
