import { Col, Form } from "react-bootstrap";
//import DatePicker from 'react-datepicker';
import { SettingField } from "../../types/settings-request";

export type FormFieldProps = {
    fld: SettingField,
    value: any,
    errorMessage: any,
    disabled: boolean,
    change(e: any): void
}

const FormField = (props: FormFieldProps) => {

    const setField = (e: any) => {
        props.change(e);
    }

    const renderFieldControl = (fld: SettingField) => {
        if (fld.type === "datetime") {
            return <Form.Control
                type="datetime-local"
                value={props.value == null ? "" : props.value}
                isInvalid={!!props.errorMessage}
                readOnly={props.disabled}
                onChange={(e) => setField(e.target.value)}
            />
        }
        if (fld.type === "number" || fld.type === "integer") {
            return <Form.Control
                type="number"
                value={props.value == null ? "" : props.value}
                isInvalid={!!props.errorMessage}
                readOnly={props.disabled}
                onChange={(e) => setField(e.target.value)}
            />
        }
        return <Form.Control
            value={props.value == null ? "" : props.value}
            isInvalid={!!props.errorMessage}
            readOnly={props.disabled}
            onChange={e => setField(e.target.value)}
        />
    }

    const renderLocalError = (fld: SettingField) => {
        if (!props.errorMessage)
            return null;
        if (fld.type === "date")
            return <div className="local-error mt-1">{props.errorMessage}</div>
        else
            return <Form.Control.Feedback type="invalid">{props.errorMessage}</Form.Control.Feedback>
    }

    return <Col key={props.fld.key} xs={12} sm={6} md={4}>
        <Form.Group className="mb-3.fld" controlId={props.fld.key}>
            <Form.Label>{props.fld.title}</Form.Label>
            {renderFieldControl(props.fld)}
            {renderLocalError(props.fld)}
        </Form.Group>
    </Col>
}

export default FormField;
