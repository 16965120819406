import { $t} from "hooks/i18n";
import { validateEmail } from "plugins/validation";
import React from "react";
import { useState } from "react";
import { Col, Row, Button, Modal, Form } from "react-bootstrap";
import "../../styles/modal.scss";


type EmailModalProps = {
	show: boolean,
	title: string,
	message: string
	onCancel: () => void,
	onOk: (email: any) => void
}

const EmailModal = (props: EmailModalProps) => {
	const [emailAdress, setEmailAdress] = useState<string>();
	const [localErrors, setLocalErrors] = React.useState<{ [key: string]: any }>({});

	const validate = () => {
		let errorText = "Emaili formaat ei ole korrektne"
		let locErrors = { ...localErrors, email: null } as { [key: string]: any };
		let isValid = true;

		if (!emailAdress) {
			isValid = false;
			locErrors.email = errorText;
		}
		else if (!validateEmail(emailAdress)) {
			isValid = false;
			locErrors.email = errorText;
		}
		setLocalErrors(locErrors);
		return isValid;
	}

	const confirmedClicked = () : void => {
		if (!validate())
			return;

		props.onOk(emailAdress)
	}

	return <Modal show={props.show} onHide={props.onCancel} className="ra-modal" size="lg">
		<Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Row className="mb-3">
				<Col xs={12}>
					<h3>{props.message}</h3>
					<Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
						<Form.Label>Emaili aadress <span className="req-indic">*</span></Form.Label>
						<Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmailAdress(e.target.value)} value={emailAdress} isInvalid={!!localErrors.email}/>	
						{!!localErrors.email ? <Form.Control.Feedback type="invalid">{localErrors.email}</Form.Control.Feedback> : null}				
      				</Form.Group>
				</Col>
			</Row>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={props.onCancel}>
				{$t("Cancel")}
			</Button>
			<Button variant="primary" onClick={() => confirmedClicked()}>
				{$t("Confirm")}
			</Button>
		</Modal.Footer>
	</Modal>;
}

export default EmailModal;
